import Cookies from "js-cookie";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";

import ImageLogo from "../Assets/logo_title.png";
import { UserContext, UserInterface } from "../Context/UserContext";
import RoutesPath from "../Router/RoutesPath";
import MenuButton from "./MenuButton";
import RoundedButton from "./RoundedButton";

const handleDisconnect = () => {
  Cookies.remove("jwt");
  console.log("Loged Out");
  window.location.href = "/";
};

const Menu = () => {
  const { user } = useContext(UserContext) as UserInterface;
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "16px",
        border: "1px solid #E5E5E5",
        height: "100vh",
        boxSizing: "border-box",
        width: 300,
        minWidth: 300,
        position: "fixed",
        minHeight: 650,
        backgroundColor: "white",
      }}
    >
      <img
        src={ImageLogo}
        style={{
          width: "135px",
          marginBottom: 25,
          marginLeft: "auto",
          marginTop: 15,
          marginRight: "auto",
          cursor: "pointer",
        }}
        alt="logo"
        onClick={() => {
          navigate(RoutesPath.home);
        }}
      />
      <NavLink
        to={RoutesPath.campaigns}
        className={({ isActive }) => (isActive ? "filterPurple" : "navLink")}
      >
        <MenuButton name={t("campaigns")} icon="campaigns" />
      </NavLink>
      <NavLink
        to={RoutesPath.actions}
        className={({ isActive }) => (isActive ? "filterPurple" : "navLink")}
      >
        <MenuButton name={t("actionsToBeTaken")} icon="actions" />
      </NavLink>
      <NavLink
        to={RoutesPath.quote}
        className={({ isActive }) => (isActive ? "filterPurple" : "navLink")}
      >
        {({ isActive }) => (
          <MenuButton
            name={t("quoteRequest")}
            icon="quote"
            current={isActive}
          />
        )}
      </NavLink>
      <NavLink
        to={RoutesPath.contact}
        className={({ isActive }) => (isActive ? "filterPurple" : "navLink")}
      >
        <MenuButton name={t("covworldContact")} icon="contact" />
      </NavLink>
      <NavLink
        to={RoutesPath.search}
        className={({ isActive }) => (isActive ? "filterPurple" : "navLink")}
      >
        <MenuButton name={t("advancedSearch")} icon="search" />
      </NavLink>

      <div style={{ position: "absolute", bottom: 0, width: "268px" }}>
        <RoundedButton
          text={t("newQuote")}
          action={() => navigate(`${RoutesPath.quote}?new=true`)}
          short
        />
        <NavLink
          to={RoutesPath.settings}
          className={({ isActive }) => (isActive ? "filterPurple" : "navLink")}
        >
          <MenuButton name={t("settings")} icon="settings" />
        </NavLink>
        <div style={{ color: "#344054" }}>
          <MenuButton
            name={t("logout")}
            icon="logout"
            onClick={handleDisconnect}
          />
        </div>
        <hr style={{ border: "1px solid #EAECF0", borderBottom: "none" }} />
        <p
          style={{
            color: "#344054",
            fontSize: 14,
            marginBottom: 0,
            marginLeft: 8,
          }}
        >
          {user?.firstName} {user?.lastName}
        </p>
        <p
          style={{
            color: "#667085",
            fontSize: 14,
            marginTop: 0,
            marginLeft: 8,
          }}
        >
          {user?.email}
        </p>
      </div>
    </div>
  );
};

export default Menu;
