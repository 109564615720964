/* eslint-disable react-hooks/exhaustive-deps */
import Cookies from "js-cookie";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Api from "../Services/API";
import { Action, Campaign } from "../Types/campaign.type";

interface DecodedInterface {
  id: number;
  lastName: string;
  firstName: string;
  email: string;
  iat: number;
  exp: number;
}

export type UserInterface = {
  user: DecodedInterface;
  setUser: (decodedUser: DecodedInterface) => void;
  data: Campaign[];
  action: Action[];
  notifications: number;
  refreshData: () => void; // Add this line
  userContextLoading: boolean;
};

export const UserContext = createContext<UserInterface | null>(null);

const UserProvider: React.FC<any> = ({ children }) => {
  const [data, setData] = useState<Campaign[]>([]);
  const [action, setAction] = useState<Action[]>([]);
  const [notifications, setNotifications] = useState<number>(0);
  const [user, setUser] = useState<DecodedInterface>({
    id: 0,
    firstName: "firstName",
    lastName: "lastName",
    email: "default@email.com",
    iat: 0,
    exp: 0,
  });
  const [userContextLoading, setUserContextLoading] = useState<boolean>(true);
  const { t } = useTranslation();

  const getData = async () => {
    try {
      const ret = await Api.getData();
      setUserContextLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setData(ret.data);
    } catch (error) {
      console.log("getData Error:", error);
    }
  };

  const getAction = async () => {
    try {
      const ret = await Api.getActions();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      setAction(ret.data.actions);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      setNotifications(ret.data.notifications);
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      if ((error as any).response?.data?.message === "disabled") {
        toast.warn(t("userDisabled"), {
          icon: false,
        });
        Cookies.remove("jwt");
        console.log("Loged Out");
        window.location.href = "/";
      }
      console.log("getAction Error:", error);
    }
  };

  const refreshData = async () => {
    if (user.id !== 0) {
      await getData();
      await getAction();
    }
  };

  useEffect(() => {
    refreshData();
  }, [user]);

  const context = useMemo(() => {
    return {
      user,
      setUser,
      data,
      action,
      notifications,
      refreshData,
      userContextLoading,
    };
  }, [user, setUser, data, action, notifications, userContextLoading]);
  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
