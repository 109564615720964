import { AxiosResponse } from "axios";

import Client from "./Client";

const login = (email: string, password: string) =>
  Client.post("/client/login", { email, password });
const register = async (content: any) =>
  Client.post("/client/register", content);
const quote = async (content: any) => Client.post("/client/quote", content);
const getquotes = async () => Client.post("/client/getquotes");
const email = (userEmail: string) =>
  Client.post("/client/email", { email: userEmail });
const newPassword = (password: string, token: string, userEmail: string) =>
  Client.post("/client/newpassword", {
    password,
    token,
    email: userEmail,
  });
const editPassword = (currentPassword: string, password: string) =>
  Client.post("/client/editpassword", {
    currentPassword,
    newPassword: password,
  });

const getData = () => Client.get("/campaign/client-campaigns");
const getActions = () => Client.get("/client/actions");
const getPicture = (pictureId: string) =>
  Client.get(`/activity/picturePortal/${pictureId}`);
const getUserEnterprise = () => Client.get("/client/enterprise");
const getContact = () => Client.get("/client/contact");
const getAwsPresignedUrl = (fileName: string, fileType: string) =>
  Client.post("/client/getawspresignedurl", { fileName, fileType });
const addToDatabase = (
  fileName: string,
  key: string,
  campaignId: string,
  type: string,
  quoteId?: number
) =>
  Client.post("/client/addtodatabase", {
    fileName,
    key,
    campaignId,
    type,
    category: "creation",
    quoteId,
  });
const reviewDocument = (documentId: number, result: boolean, text: string) =>
  Client.post("/client/reviewdocument", {
    documentId,
    result,
    text,
  });
const postFeedback = (campaignId: number, form: unknown) =>
  Client.post("/client/feedback", { campaignId, form });
const downloadOneActivity = (activityId: number) =>
  Client.post(
    "/client/downloadoneactivity",
    { activityId },
    { responseType: "blob" }
  );
const downloadOneMission = (missionId: number) =>
  Client.post(
    "/client/downloadonemission",
    { missionId },
    { responseType: "blob" }
  );
const downloadOneCampaign = (campaignId: number) =>
  Client.post(
    "/client/downloadonecampaign",
    { campaignId },
    { responseType: "blob" }
  );
const downloadMissionExcel = (missionId: number) =>
  Client.post(
    "/client/downloadmissionexcel",
    { missionId },
    { responseType: "blob" }
  );

const Api = {
  login,
  email,
  newPassword,
  editPassword,
  getData,
  getActions,
  getPicture,
  getUserEnterprise,
  getContact,
  getAwsPresignedUrl,
  addToDatabase,
  reviewDocument,
  postFeedback,
  downloadOneActivity,
  downloadOneMission,
  downloadOneCampaign,
  downloadMissionExcel,
  register,
  quote,
  getquotes,
};

export default Api;
