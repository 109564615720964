import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { useContext, useEffect, useState } from "react";

import { UserContext, UserInterface } from "../Context/UserContext";
import Client from "../Services/Client";

interface DecodedInterface {
  id: number;
  lastName: string;
  firstName: string;
  email: string;
  iat: number;
  exp: number;
}

const useAuthStatus = () => {
  const [loggedIn, setLoggedIn] = useState<boolean | undefined>();
  const { setUser } = useContext(UserContext) as UserInterface;

  useEffect(() => {
    const jwt = Cookies.get("jwt");
    if (!jwt) {
      setLoggedIn(false);
      return;
    }
    Client.defaults.headers.common.Authorization = `Bearer ${jwt}`;

    const decoded: DecodedInterface = jwt_decode(jwt);
    setLoggedIn(true);
    setUser(decoded);
  }, [setUser]);

  return loggedIn;
};

export default useAuthStatus;
