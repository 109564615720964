const UneditableInputBox = ({
  content,
  title,
}: {
  content?: string;
  title?: string;
}) => {
  return (
    <div>
      {title && <p style={{ marginBottom: 6 }}>{title}</p>}
      <input
        style={{
          width: 350,
          height: 44,
          background: "#F9FAFB",
          borderRadius: 8,
          boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05",
          border: "1px solid #D0D5DD",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: 16,
          paddingLeft: 12,
          color: "#667085",
          boxSizing: "border-box",
        }}
        readOnly
        value={content}
      />
    </div>
  );
};
export default UneditableInputBox;
