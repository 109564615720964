/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable unicorn/consistent-function-scoping */
import { LoadingOverlay, Menu, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconDownload } from "@tabler/icons-react";
import { AxiosResponse } from "axios";
import { saveAs } from "file-saver";
import React from "react";
import { useTranslation } from "react-i18next";

import Api from "../Services/API";

const Downloader = ({
  activityId = 0,
  activityName,
  missionId = 0,
  missionName,
  campaignId = 0,
  campaignName,
}: {
  activityId?: number;
  activityName?: string;
  missionId?: number;
  missionName?: string;
  campaignId?: number;
  campaignName?: string;
}) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const isMission =
    window.location.pathname.includes("campaign/") &&
    !window.location.pathname.includes("mission");
  const isActivity = window.location.pathname.includes("mission/");
  const isCampaign =
    window.location.pathname === "/" ||
    window.location.pathname === "/campaign";
  const { t } = useTranslation();

  const handlePdfDownload = async () => {
    setLoading(true);
    try {
      if (isActivity) {
        const res: AxiosResponse = await Api.downloadOneActivity(activityId);
        console.log(res.status);
        const blob = new Blob([res.data], {
          type: res.headers["content-type"],
        });
        if (activityName) saveAs(blob, `${activityName ?? "activité"}.pdf`);
      } else if (isMission) {
        const res: AxiosResponse = await Api.downloadOneMission(missionId);
        console.log(res.status);
        const blob = new Blob([res.data], {
          type: res.headers["content-type"],
        });
        saveAs(blob, `${missionName ?? "mission"}.zip`);
      } else if (isCampaign) {
        const res: AxiosResponse = await Api.downloadOneCampaign(campaignId);
        console.log(res.status);
        const blob = new Blob([res.data], {
          type: res.headers["content-type"],
        });
        saveAs(blob, `${campaignName ?? "campagne"}.zip`);
      }
    } catch (error) {
      console.error("An error occurred while downloading:", error);
    }
    setLoading(false);
  };

  const handleClickExcel = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const res: AxiosResponse = await Api.downloadMissionExcel(missionId);
      console.log(res.status);
      const blob = new Blob([res.data], { type: res.headers["content-type"] });
      saveAs(blob, `${missionName ?? "mission"}.xlsx`);
    } catch (error) {
      console.error("An error occurred while downloading:", error);
    }
  };

  const openModal = () =>
    modals.openConfirmModal({
      title: t("confirmDownload"),
      children: (
        <Text size="sm">
          {t("download")}{" "}
          {isActivity ? t("theActivityReport") : t("activityReports")}
        </Text>
      ),
      labels: { confirm: t("confirm"), cancel: t("cancel") },
      onConfirm: handlePdfDownload,
    });

  const handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    openModal();
  };

  const handleClickTarget = (e: any) => {
    if (isMission) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      handleClick(e);
    }
  };

  return (
    <div>
      <Menu trigger="hover" openDelay={0} closeDelay={400}>
        <Menu.Target>
          <IconDownload size={16} onClick={handleClickTarget} />
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item icon={<IconDownload size={14} />} onClick={handleClick}>
            {!isActivity
              ? t("downloadPDFsOfActivityReports")
              : t("downloadThePDFOfTheActivityReport")}
          </Menu.Item>
          {isMission && (
            <Menu.Item
              icon={<IconDownload size={14} />}
              onClick={handleClickExcel}
            >
              {t("downloadTheExcelOfActivityReports")}
            </Menu.Item>
          )}
        </Menu.Dropdown>
        <LoadingOverlay visible={loading} />
      </Menu>
    </div>
  );
};

export default Downloader;
