/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import { getPercentagesOfActivities } from "../Utils/tableCalculation";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ pieData }: { pieData: [number, number, number] }) => {
  const [toDo, inProgress, done] = pieData;
  const colors = ["#5925DC", "#2E90FA", "#32D583"];
  const total = toDo + inProgress + done;
  const [percentToDo, percentInProgress, percentFinished] =
    getPercentagesOfActivities(total, toDo, done);
  const { t } = useTranslation();

  const data = {
    labels: [
      `${t("notStarted")}: ${percentToDo}%`,
      `${t("inProgress")}: ${percentInProgress}%`,
      `${t("completed")}: ${percentFinished}%`,
    ],
    datasets: [
      {
        data: pieData,
        backgroundColor: colors,
        borderColor: colors,
        tooltip: {
          callbacks: {
            label(context: any) {
              const value = context.formattedValue;
              const activityString =
                value === "1"
                  ? t("activity").toLocaleLowerCase()
                  : t("activities").toLocaleLowerCase();
              return `${context.label}, ${value} ${activityString}`;
            },
          },
        },
      },
    ],
  };

  if (total === 0) return null;
  return (
    <div
      style={{
        width: 300,
        backgroundColor: "white",
        border: "1px solid #EAECF0",
        borderRadius: 8,
        boxShadow:
          "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
        padding: 20,
        marginTop: 24,
      }}
    >
      <p
        style={{
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "24px",
          color: "#101828",
          margin: 0,
        }}
      >
        {t("progressOfActivities")}
      </p>
      <Doughnut data={data} />
    </div>
  );
};
export default DoughnutChart;
