/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import classNames from "classnames";
import { useTranslation } from "react-i18next";

type BoxProps = {
  boxes: string[];
  filterMode: string;
  setFilterMode: React.Dispatch<React.SetStateAction<string>>;
  setData: React.Dispatch<React.SetStateAction<any[]>>;
  rawDataExtended: any[];
};

const SelectBox = ({
  boxes,
  filterMode,
  setFilterMode,
  setData,
  rawDataExtended,
}: BoxProps) => {
  const { t } = useTranslation();

  return (
    <div className="boxBoxSelect">
      {boxes.map((box) => (
        <div
          onClick={() => {
            setFilterMode(box);
            if (box === t("all")) setData(rawDataExtended);
            else
              setData(rawDataExtended.filter((e) => e.statusDisplay === box));
          }}
          className={classNames("boxSelect", {
            greyBackgroundSelect: filterMode === box,
          })}
          key={box}
        >
          {box}
        </div>
      ))}
    </div>
  );
};
export default SelectBox;
