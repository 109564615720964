import { IconFileText } from "@tabler/icons-react";
import { ElementType, useContext } from "react";

import ImageActions from "../Assets/Icons/action.svg";
import ImageCampaigns from "../Assets/Icons/campaign.svg";
import ImageContact from "../Assets/Icons/contact.svg";
import ImageLogout from "../Assets/Icons/logout.svg";
import ImageSearch from "../Assets/Icons/search.svg";
import ImageSettings from "../Assets/Icons/settings.svg";
import { UserContext, UserInterface } from "../Context/UserContext";

const stringToImage: Record<string, string | ElementType> = {
  campaigns: ImageCampaigns,
  actions: ImageActions,
  contact: ImageContact,
  settings: ImageSettings,
  logout: ImageLogout,
  search: ImageSearch,
  quote: IconFileText,
};

type MenuButtonProps = {
  name: string;
  icon: string;
  current?: boolean;
  onClick?: () => void;
};

const MenuButton = ({
  name,
  icon,
  current = false,
  onClick,
}: MenuButtonProps) => {
  const { notifications } = useContext(UserContext) as UserInterface;

  const renderIcon = () => {
    const Icon = stringToImage[icon];
    if (typeof Icon === "string") {
      return (
        <img src={Icon} alt="Icon" style={{ maxWidth: 22, maxHeight: 22 }} />
      );
    }
    return (
      <Icon
        style={{
          maxWidth: 22,
          maxHeight: 22,
          color: current ? "#727fdb" : "#667085",
        }}
      />
    );
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: 40,
        borderRadius: 6,
        padding: "8px 12px",
        gap: 14,
        marginTop: 2,
        marginBottom: 2,
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      {renderIcon()}
      <p
        style={{
          margin: 0,
          height: 24,
          fontSize: 16,
          fontWeight: 400,
          textDecoration: "none",
        }}
      >
        {name}
      </p>
      {icon === "actions" && notifications > 0 && (
        <div
          style={{
            backgroundColor: "#D92D20",
            padding: "2px 8px",
            borderRadius: 8,
            color: "white",
            fontWeight: 600,
            fontSize: 12,
            height: 18,
          }}
        >
          {notifications}
        </div>
      )}
    </div>
  );
};

export default MenuButton;
