type InputBoxProps = {
  text?: string;
  type?: string;
  large?: boolean;
  value?: string | undefined;
  placeholder?: string;
  setInput: React.Dispatch<React.SetStateAction<string>>;
};

const InputBox = ({
  text,
  type = "",
  large,
  value,
  placeholder = "",
  setInput,
}: InputBoxProps) => {
  return (
    <div style={{ marginBottom: 16 }}>
      <label
        style={{
          marginBottom: 6,
          display: "block",
          fontSize: 14,
        }}
      >
        {text}
      </label>
      <input
        className="inputBox"
        style={{
          boxSizing: "border-box",
          width: large ? 350 : undefined,
          height: large ? 44 : undefined,
        }}
        placeholder={placeholder.length > 0 ? placeholder : text}
        value={value}
        type={type}
        onChange={(e) => setInput(e.target.value)}
      />
    </div>
  );
};
export default InputBox;
