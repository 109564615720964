import { Center, Flex, PasswordInput, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import arrowLeft from "../Assets/Icons/arrow_left.svg";
import RoundedButton from "../Components/RoundedButton";
import RoutesPath from "../Router/RoutesPath";
import Api from "../Services/API";
import Client from "../Services/Client";

const meetReq = (password: string) => {
  return (
    password.length >= 8 &&
    (password.match(/[a-z]/) || password.match(/[A-Z]/)) &&
    password.match(/\d/)
  );
};

const Register = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const form = useForm<{
    firstName: string;
    lastName: string;
    company: string;
    email: string;
    phone: string;
    password: string;
    passwordConfirmation: string;
  }>({
    initialValues: {
      firstName: "",
      lastName: "",
      company: "",
      email: "",
      phone: "",
      password: "",
      passwordConfirmation: "",
    },
    validate: {
      password: (value) =>
        value.length === 0
          ? t("thisFieldIsRequired")
          : !meetReq(value)
          ? t("passwordNotMeetingRequirements")
          : null,
      passwordConfirmation: (value, values) =>
        value !== values.password ? t("passwordsDoNotMatch") : null,
      firstName: (value) =>
        value.length === 0 ? t("thisFieldIsRequired") : null,
      lastName: (value) =>
        value.length === 0 ? t("thisFieldIsRequired") : null,
      company: (value) =>
        value.length === 0 ? t("thisFieldIsRequired") : null,
      email: (value) => (value.length === 0 ? t("thisFieldIsRequired") : null),
      phone: (value) => (value.length === 0 ? t("thisFieldIsRequired") : null),
    },
  });

  const handleLogin = async () => {
    try {
      const ret = await Api.login(form.values.email, form.values.password);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
      Client.defaults.headers.common.Authorization = `Bearer ${ret.data.jwt}`; // ranger dans le local storage
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      Cookies.set("jwt", ret.data.jwt);
      window.location.href = "/";
    } catch (error) {
      toast.warn(t("error"), {
        icon: false,
      });
      console.log(error);
    }
  };

  const submitForm = async () => {
    if (form.validate().hasErrors) {
      console.log("Validation errors", form.errors);
    } else {
      try {
        const ret = await Api.register(form.values);
        console.log("Form feedback", ret.data);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { success } = ret.data;
        if (success) {
          console.log("Success");
          await handleLogin();
        }
      } catch {
        toast.warn(t("errorOrEmailAlreadyUsed"), {
          icon: false,
        });
      }
    }
  };

  return (
    <Center p={20} display="flex">
      <div>
        <Flex>
          <img
            src={arrowLeft}
            alt="Icon"
            style={{
              marginTop: -10,
              cursor: "pointer",
            }}
            onClick={() => navigate(RoutesPath.home)}
          />
          <Title mb={20} ml={30}>
            {t("signUp")}
          </Title>
        </Flex>
        <TextInput
          label={t("firstName")}
          {...form.getInputProps("firstName")}
          withAsterisk
          miw={300}
        />
        <TextInput
          label={t("lastName")}
          {...form.getInputProps("lastName")}
          withAsterisk
          miw={300}
        />
        <TextInput
          label={t("company")}
          {...form.getInputProps("company")}
          withAsterisk
          miw={300}
        />
        <TextInput
          label={t("email")}
          {...form.getInputProps("email")}
          withAsterisk
          miw={300}
        />
        <TextInput
          label={t("phone")}
          {...form.getInputProps("phone")}
          withAsterisk
          miw={300}
        />
        <PasswordInput
          label={t("password")}
          description={t(
            "thePasswordMustContainAtLeast8CharactersOneLetterAndOneNumber"
          )}
          {...form.getInputProps("password")}
          withAsterisk
          miw={300}
        />
        <PasswordInput
          label={t("confirmPassword")}
          {...form.getInputProps("passwordConfirmation")}
          withAsterisk
          miw={300}
        />
        <Center>
          <RoundedButton text={t("register")} action={submitForm} />
        </Center>
      </div>
    </Center>
  );
};

export default Register;
