/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useTranslation } from "react-i18next";

import { Activity } from "../../Types/activity.type";
import GlowingIcon from "../GlowingIcon";
import DisplayDynamicForm from "./DisplayDynamicForm";
import { FilledForm, IForm } from "./displayFormTypes";

type Props = {
  activity: Activity | undefined;
};

const DisplayReport = ({
  report,
  filledReport,
  incidentName,
}: {
  report: string;
  filledReport: string;
  incidentName?: string;
}) => {
  const form: IForm = JSON.parse(report);
  const filledForm: FilledForm = JSON.parse(filledReport);
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: 20,
        marginTop: 24,
      }}
    >
      {incidentName && (
        <div
          style={{
            marginTop: 0,
            fontSize: 14,
            color: "#D92D20",
            display: "flex",
            fontWeight: 500,
          }}
        >
          <GlowingIcon icon="warning" color="red" />
          {t("incidentType")} : {incidentName}
        </div>
      )}
      <DisplayDynamicForm form={form} filledForm={filledForm} />
    </div>
  );
};

export const DisplayInterventionReports = ({ activity }: Props) => {
  const { t } = useTranslation();
  if (!activity) return null;
  const reports = activity.serviceProviderResourceHistory.flatMap(
    (h) => h.filledReport
  );
  return (
    <>
      {reports.map((i, index) => {
        if (!i) return null;
        return (
          <div key={i.id}>
            <h2 style={{ marginTop: 30, fontWeight: 600 }}>
              {t("interventionReport")}
            </h2>
            <DisplayReport
              key={i.id}
              report={i.report}
              filledReport={i.filledReport}
            />
          </div>
        );
      })}
    </>
  );
};

export const DisplayIncidentReports = ({ activity }: Props) => {
  const { t } = useTranslation();
  if (!activity) return null;
  const incidents = activity.serviceProviderResourceHistory.flatMap(
    (h) => h.incidents
  );
  return (
    <>
      {incidents.map((i, index) => {
        if (!i) return null;
        return (
          <div key={i.id}>
            <h2 style={{ marginTop: 30, fontWeight: 600 }}>
              {t("incidentReport")} N°{index + 1}
            </h2>
            <DisplayReport
              key={`filled-incident-report-${i.id}`}
              incidentName={i.incident?.name}
              report={i.report}
              filledReport={i.filledReport}
            />
          </div>
        );
      })}
    </>
  );
};
