/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import axios from "axios";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

interface DecodedInterface {
  id: number;
  lastName: string;
  firstName: string;
  email: string;
  iat: number;
  exp: number;
}

const Client = axios.create({
  baseURL: window.location.href.includes("https://client.covworld.fr")
    ? "https://d3uwzl3k7h1sxi.cloudfront.net/v1"
    : window.location.href.includes("stagingclient") ||
      window.location.href.includes("cloudfront")
    ? "https://d2paxasvg3onmo.cloudfront.net/v1"
    : "http://localhost:3000/v1",
});

// Add a request interceptor
Client.interceptors.request.use(
  (config) => {
    // For the login routes only, we don't verify the jwt
    if (
      config.url &&
      [
        "/client/login",
        "/client/email",
        "/client/newpassword",
        "/client/register",
      ].includes(config.url)
    )
      return config;

    const authHeader = <string>(config.headers?.common as any).Authorization;
    const token = authHeader && authHeader.split(" ")[1];
    const decoded: DecodedInterface = jwt_decode(token);

    if (decoded.exp < Date.now() / 1000) {
      Cookies.remove("jwt");
      window.location.href = "/"; // redirect to login if cookies are outdated
      // we use this method in order to reload the page
    }
    return config;
  },
  (error) => {
    console.log("error", error);
    return Promise.reject(error);
  }
);

export default Client;
