const RoundedButton = ({
  text,
  near = false,
  action,
  color,
  short,
}: {
  text: string;
  near?: boolean;
  action: (() => Promise<void>) | (() => void);
  color?: string;
  short?: boolean;
}) => {
  return (
    <button
      className="roundedButton"
      style={{
        marginTop: near ? 0 : undefined,
        backgroundColor: color,
        borderColor: color,
        width: short ? 260 : undefined,
      }}
      onClick={() => {
        action();
      }}
      type="submit"
    >
      {text}
    </button>
  );
};
export default RoundedButton;
