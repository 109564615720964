/* eslint-disable react/no-unstable-nested-components */
import { Input, Text } from "@mantine/core";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import moment from "moment";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ImageSearch from "../Assets/Icons/search.svg";
import Layout from "../Components/Layout";
import MainTable from "../Components/MainTable";
import MomentDateFormat from "../Components/MomentDateFormat";
import SquareButton from "../Components/SquareButton";
import { UserContext, UserInterface } from "../Context/UserContext";

type ActivityResult = {
  campaignId: number;
  campaignName: string;
  missionId: number;
  missionName: string;
  activityId: number;
  activityName: string;
  startDate: Date;
  endDate: Date;
};

const columnHelper = createColumnHelper<ActivityResult>();

const Search = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const { data } = useContext(UserContext) as UserInterface;
  const [activities, setActivities] = useState<ActivityResult[]>([]);
  const [displayResults, setDisplayResults] = useState(false);

  const search = () => {
    setDisplayResults(true);
    const newActivities = [];
    for (const campaign of data) {
      for (const mission of campaign.missions) {
        for (const activity of mission.activities) {
          if (
            JSON.stringify(activity).toLowerCase().includes(value.toLowerCase())
          ) {
            newActivities.push({
              campaignId: campaign.id,
              campaignName: campaign.name,
              missionId: mission.id,
              missionName: mission.name,
              activityId: activity.id,
              activityName: activity.name,
              startDate: campaign.startDate,
              endDate: campaign.endDate,
            });
          }
        }
      }
    }
    setActivities(newActivities);
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const columns = useMemo(
    () => [
      columnHelper.accessor("campaignName", {
        header: () => <span>{t("campaign")}</span>,
        cell: (props) => (
          <>
            <div>{props.getValue()}</div>
            <div style={{ color: "#667085" }}>
              <MomentDateFormat
                date1={props.row.original.startDate}
                date2={props.row.original.endDate}
              />
            </div>
          </>
        ),
        enableSorting: false,
      }),
      columnHelper.accessor("missionName", {
        header: () => <span>{t("mission")}</span>,
        cell: (props) => <div>{props.getValue()}</div>,
        enableSorting: false,
      }),
      columnHelper.accessor("activityName", {
        header: () => <span>{t("activity")}</span>,
        cell: (props) => <div>{props.getValue()}</div>,
        enableSorting: false,
      }),
    ],
    [t]
  );

  const table = useReactTable({
    data: activities,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Layout title={t("searchForFieldInActivityReports")}>
      <Input
        icon={<img src={ImageSearch} alt="Search" />}
        placeholder={t("search")}
        styles={{ input: { height: 44, fontSize: 16 } }}
        mt={30}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
        onChange={(e: any) => setValue(e?.currentTarget?.value)}
        onKeyPress={(e: React.KeyboardEvent) => {
          if (e.key === "Enter") {
            search();
          }
        }}
      />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div>
          <SquareButton color="grey" text={t("search")} action={search} />
        </div>
      </div>
      <div>
        <div>
          {displayResults && (
            <h2 style={{ marginTop: 30, fontWeight: 600 }}>
              {t("searchResults")}
            </h2>
          )}
          {activities.length > 0 ? (
            //  eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            <MainTable table={table} search />
          ) : displayResults ? (
            <Text>{t("noResults")}</Text>
          ) : null}
        </div>
      </div>
    </Layout>
  );
};

export default Search;
