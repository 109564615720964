import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import InputBox from "../Components/InputBox";
import RoundedButton from "../Components/RoundedButton";
import Api from "../Services/API";
import { PageTop } from "./Login";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [alreadySent, setAlreadySent] = useState(false);
  const { t } = useTranslation();

  const handleSendMail = async () => {
    if (email.length === 0) return;
    try {
      setAlreadySent(true);
      const ret = await Api.email(email);
      toast(t("ifTheUserExistsEmailSent"));
    } catch {
      toast(t("ifTheUserExistsEmailSent"));
    }
  };

  return (
    <div className="whiteWrapper">
      <div className="loginStyle">
        <PageTop content={t("passwordForgotten")} arrow />
        <InputBox text={t("emailAddress")} setInput={setEmail} />
        {alreadySent && (
          <p className="emailSent" style={{ maxWidth: 320 }}>
            {t(
              "ifTheUserExistsEmailSentToTheProvidedAddressIfYouHaveNotReceivedAConfirmationEmailPleaseCheckYourSpamFolder"
            )}
          </p>
        )}
        <RoundedButton
          text={alreadySent ? t("resendEmail") : t("resetPassword")}
          action={handleSendMail}
        />
      </div>
    </div>
  );
};

export default ResetPassword;
