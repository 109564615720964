import "./App.css";
import "react-toastify/dist/ReactToastify.css";

import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { ToastContainer } from "react-toastify";

import UserProvider from "./Context/UserContext";
import Router from "./Router/Router";

const App = () => {
  // The UserProvider Context store all the information about the user
  return (
    <UserProvider>
      <MantineProvider
        theme={{
          radius: {
            sm: "8px",
            s4: "4px",
          },
          colors: {
            blue: [
              "#F3F0FF",
              "#E5DBFF",
              "#D0BFFF",
              "#B197FC",
              "#9775FA",
              "#845EF7",
              "#7280DC", // covworld logo
              "#5b69c5", // covworld logo darker
              "#6741D9",
              "#5F3DC4",
            ],
          },
        }}
      >
        <ModalsProvider>
          <Router />
          <ToastContainer className="toastifyStyle" />
        </ModalsProvider>
      </MantineProvider>
    </UserProvider>
  );
};

export default App;
