import { PieChart as PieChartReact } from "react-minimal-pie-chart";

const color = (percent: number) => {
  return percent === 100 ? "#12B76A" : "#151E37";
};

const PieChart = ({ percentCampaign }: { percentCampaign: number }) => {
  return (
    <div className="smallPie">
      <PieChartReact
        data={[
          {
            value: percentCampaign,
            color: color(percentCampaign),
          },
        ]}
        totalValue={100}
        lineWidth={16}
        label={({ dataEntry }) => `${dataEntry.value}%`}
        labelStyle={{
          fontSize: "22px",
          fill: color(percentCampaign),
        }}
        rounded
        labelPosition={0}
        background="#EAECF0"
      />
    </div>
  );
};
export default PieChart;
