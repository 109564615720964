/* eslint-disable react/no-unstable-nested-components */
import {
  ColumnFiltersState,
  createColumnHelper,
  getCoreRowModel,
  getFacetedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { t as tt } from "i18next";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import GlowingBox from "../Components/GlowingBox";
import Layout from "../Components/Layout";
import MainTable from "../Components/MainTable";
import MomentDateFormat from "../Components/MomentDateFormat";
import { UserContext, UserInterface } from "../Context/UserContext";
import { Action, ActionType } from "../Types/campaign.type";

export const actionStatusRecord: Record<ActionType, string> = {
  waiting_for_quote: tt("waitingQuote"),
  validate_quote: tt("validateQuote"),
  submit_creation: tt("dropTheCreations"),
  waiting_for_bat: tt("waitingBAT"),
  validate_bat: tt("validateBAT"),
  customer_feedback: tt("customerFeedback"),
};

const columnHelper = createColumnHelper<Action>();

const Actions = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<Action[]>([]);
  const { action } = useContext(UserContext) as UserInterface;
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState("");

  useEffect(() => {
    setData(action);
  }, [action]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: t("campaign"),
        cell: (props) => (
          <>
            <div style={{ position: "relative" }}>
              {[
                "validate_quote",
                "submit_creation",
                "validate_bat",
                "customer_feedback",
              ].includes(props.row.original.type) && (
                <div
                  style={{
                    position: "absolute",
                    width: 8,
                    height: 8,
                    backgroundColor: "#D92D20",
                    marginLeft: -12,
                    marginTop: 5,
                    borderRadius: 4,
                  }}
                />
              )}
              {props.getValue()}
            </div>
            <div style={{ color: "#667085" }}>
              <MomentDateFormat
                date1={props.row.original.startDate}
                date2={props.row.original.endDate}
              />
            </div>
          </>
        ),
      }),
      columnHelper.accessor("type", {
        header: t("status"),
        cell: (props) => (
          <GlowingBox text={actionStatusRecord[props.row.original.type]} />
        ),
      }),
      columnHelper.accessor("numberMissions", {
        header: t("missions"),
        cell: (info) => (
          <div style={{ paddingLeft: 26 }}>{info.renderValue()}</div>
        ),
        enableSorting: false,
      }),
      columnHelper.accessor("numberActivities", {
        header: t("activities"),
        cell: (info) => (
          <div style={{ paddingLeft: 26 }}>{info.renderValue()}</div>
        ),
        enableSorting: false,
      }),
    ],
    [t]
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  return (
    <Layout
      title={t("actionsToBeTaken")}
      subtitle={t("monitoringTheActionsNecessaryForTheLaunchOfYourCampaigns")}
    >
      {action.length > 0 ? (
        <MainTable table={table} path="/campaign/" actions />
      ) : (
        <div>{t("youHaveNoActionToTake")}</div>
      )}
    </Layout>
  );
};

export default Actions;
