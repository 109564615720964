/* eslint-disable react/no-unstable-nested-components */
import { Checkbox } from "@mantine/core";
import {
  ColumnFiltersState,
  createColumnHelper,
  getCoreRowModel,
  getFacetedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import arrowLeft from "../Assets/Icons/arrow_left.svg";
import DebouncedInput from "../Components/DebouncedInput";
import DoughnutChart from "../Components/DoughnutChart";
import Downloader from "../Components/Downloader";
import GlowingBox from "../Components/GlowingBox";
import Layout from "../Components/Layout";
import MainTable from "../Components/MainTable";
import SelectBox from "../Components/SelectBox";
import { UserContext, UserInterface } from "../Context/UserContext";
import RoutesPath from "../Router/RoutesPath";
import { ExtendedActivity } from "../Types/extendedActivity";
import createExtendedMission from "../Utils/createExtendedMission";
import { storageToNumber, stringToStorage } from "../Utils/sortingString";
import { getActivitiesCountOfStatus } from "../Utils/tableCalculation";
import { activityStatusRecord } from "./Activity";

const columnHelper = createColumnHelper<ExtendedActivity>();

const Mission = () => {
  const { campaignId, missionId } = useParams();
  const { t } = useTranslation();
  const { data: rawData } = useContext(UserContext) as UserInterface;
  const rawDataCampaign = rawData.find((c) => c.id.toString() === campaignId);
  const rawDataMission = rawDataCampaign?.missions.find(
    (m) => m.id.toString() === missionId
  );
  const extendedMission = rawDataMission
    ? createExtendedMission(rawDataMission, t)
    : undefined;

  const [rawDataActivityExtended, setRawDataActivityExtended] = useState<
    ExtendedActivity[]
  >([]);
  const [data, setData] = useState<ExtendedActivity[]>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [filterMode, setFilterMode] = useState<string>(t("all"));
  const [loading, setIsLoading] = useState<boolean>(true);
  const [pieData, setPieData] = useState<[number, number, number]>([0, 0, 0]);
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: t("activity"),
        cell: (props) => (
          <>
            <div>{props.getValue()}</div>
            <div style={{ color: "#667085" }}>
              {props.row.original?.date
                ? moment(props.row.original?.date).format("DD/MM/Y")
                : "Nd"}
            </div>
          </>
        ),
      }),
      columnHelper.accessor("description", {
        header: t("description"),
        cell: (props) => {
          return (
            <div
              style={{ fontSize: 14, maxHeight: 40, overflow: "hidden" }}
              className="wrapperNoMargin"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: props.row.original.description,
              }}
            />
          );
        },
      }),
      columnHelper.accessor("statusDisplay", {
        header: t("status"),
        cell: (info) => <GlowingBox text={info.renderValue()} />,
      }),
      columnHelper.accessor("numberIncidents", {
        header: t("incidents"),
        cell: (info) => (
          <GlowingBox text={storageToNumber(info.renderValue()).toString()} />
        ),
      }),
      // columnHelper.accessor("type.productive", {
      //   header: t("productive"),
      //   cell: (props) => (
      //     <Checkbox
      //       checked={props.row.original.type.productive as any as boolean}
      //       ml={30}
      //     />
      //   ),
      // }),
      columnHelper.accessor("id", {
        header: () => "",
        cell: (props) => (
          <Downloader
            activityId={props.row.original.id}
            missionId={props.row.original.missionId}
            activityName={props.row.original.name}
          />
        ),
        enableSorting: false,
      }),
    ],
    [t]
  );

  useEffect(() => {
    if (!rawDataMission) return;
    const temp: ExtendedActivity[] = rawDataMission.activities
      .map((activity) => {
        return {
          ...activity,
          numberIncidents: stringToStorage(
            activity.serviceProviderResourceHistory
              .flatMap((h) => h.incidents)
              .length.toString()
          ),
          statusDisplay: activityStatusRecord[activity.status],
        };
      })
      .filter((activity) => activity.type.productive);
    setPieData([
      getActivitiesCountOfStatus(rawDataMission.activities, "to_do"),
      getActivitiesCountOfStatus(rawDataMission.activities, "in_progress"),
      getActivitiesCountOfStatus(rawDataMission.activities, "done"),
    ]);
    setRawDataActivityExtended(temp);
    setData(temp);
    setIsLoading(false);
  }, [rawDataMission]);

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  useEffect(() => {
    table.setSorting([{ id: "name", desc: false }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getState().columnFilters[0]?.id]);

  if (!loading && !rawDataCampaign) {
    return (
      <Layout title={t("campaign")} subtitle="">
        <h1>{t("thisCampaignCannotBeFound")}</h1>
      </Layout>
    );
  }

  return (
    <Layout>
      <div style={{ display: "flex" }}>
        <img
          src={arrowLeft}
          alt="Icon"
          style={{ marginRight: 17, cursor: "pointer" }}
          onClick={() =>
            navigate(
              RoutesPath.campaign.replace(
                ":campaignId",
                rawDataCampaign?.id.toString() ?? ""
              )
            )
          }
        />
        <h1 style={{ margin: 0 }}>{rawDataMission?.name}</h1>
        <div style={{ margin: 6 }}>
          {extendedMission?.statusDisplay && (
            <GlowingBox text={extendedMission?.statusDisplay} />
          )}
        </div>
      </div>
      <h2 style={{ marginTop: 4, color: "#98A2B3", fontSize: 13 }}>
        {rawDataCampaign?.name}
        {" / "}
        <span style={{ color: "#1C4494" }}>{rawDataMission?.name}</span>
      </h2>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 16,
        }}
      >
        <SelectBox
          boxes={[t("all"), t("toDo"), t("inProgress"), t("completed")]}
          filterMode={filterMode}
          setFilterMode={setFilterMode}
          setData={setData}
          rawDataExtended={rawDataActivityExtended}
        />
        <DebouncedInput
          value={globalFilter ?? ""}
          onChange={(value) => setGlobalFilter(String(value))}
          placeholder={t("searchAnActivity")}
          small
        />
      </div>
      <MainTable table={table} path="activity/" />
      <DoughnutChart pieData={pieData} />
    </Layout>
  );
};

export default Mission;
