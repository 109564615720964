import { toast } from "react-toastify";

const passwordCriteriaCheck = (password1: string, password2: string) => {
  const conditions = [
    {
      cond: password1.length < 8,
      text: "8 caractères minimum",
    },
    {
      cond: !/\d/.test(password1),
      text: "un chiffre minimum",
    },
    {
      cond: !/[A-Za-z]/.test(password1),
      text: "une lettre minimum",
    },
  ];

  if (password1 !== password2) {
    toast.warn("Mots de passe non identiques", { icon: false });
    return false;
  }
  if (password1.length === 0) {
    toast.warn("Le mot de passe est vide", { icon: false });
    return false;
  }

  const unmatchedCondition = conditions
    .filter((c) => c.cond)
    .map((c) => c.text)
    .join(", ");

  if (unmatchedCondition) {
    toast.warn(`Le mot de passe doit contenir ${unmatchedCondition}`, {
      icon: false,
    });
    return false;
  }
  return true;
};

export default passwordCriteriaCheck;
