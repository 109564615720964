/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useTranslation } from "react-i18next";

import {
  DynamicField,
  DynamicFilledField,
  FilledForm,
  IFieldCheckbox,
  IFieldDrawablePicture,
  IFieldFile,
  IFieldFileAndDrawable,
  IFieldSelect,
  IFieldSignature,
  IFieldText,
  IFieldTextarea,
  IFilledFieldCheckbox,
  IFilledFieldDrawable,
  IFilledFieldFile,
  IFilledFieldFileAndDrawable,
  IFilledFieldSelect,
  IFilledFieldSignature,
  IFilledFieldText,
  IFilledFieldTextarea,
  IForm,
} from "./displayFormTypes";
import ShowPicture from "./ShowPicture";

interface DisplayDynamicFormProps {
  form: IForm;
  filledForm: FilledForm;
}

interface DisplayDynamicFieldProps<T = DynamicField, U = DynamicFilledField> {
  field: T;
  filledField: U;
}

const download = (href: string, name: string) => {
  const a = document.createElement("a");

  a.download = name;
  a.href = href;

  document.body.append(a);
  a.click();
  a.remove();
};

const DisplayFieldTitle = ({ field }: { field: DynamicField }) => {
  return (
    <h4 style={{ fontSize: 14, marginRight: 5 }}>
      {field.placeholder}
      {field.required ? "*" : ""} :
    </h4>
  );
};

const DisplayTextField = ({
  field,
  filledField,
}: DisplayDynamicFieldProps<
  IFieldText | IFieldTextarea,
  IFilledFieldText | IFilledFieldTextarea
>) => {
  return (
    <div
      style={{
        display: "flex",
        marginBottom: 15,
        flexDirection: "row",
        gap: 5,
      }}
    >
      <DisplayFieldTitle field={field} />
      <h4 style={{ fontSize: 14, color: "#101828" }}>{filledField.value}</h4>
    </div>
  );
};

const DisplayCheckbox = ({
  field,
  filledField,
}: DisplayDynamicFieldProps<IFieldCheckbox, IFilledFieldCheckbox>) => {
  return (
    <div
      style={{
        display: "flex",
        marginBottom: 15,
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <DisplayFieldTitle field={field} />
      {/* <span
        style={{
          color: filledField.value ? "green" : "red",
          marginRight: 5,
        }}
        className="material-icons"
      >
        {filledField.value ? "check_circle" : "cancel"}
      </span> */}
      <h4 style={{ fontSize: 14, color: filledField.value ? "green" : "red" }}>
        {filledField.value ? "Oui" : "Non"}
      </h4>
    </div>
  );
};

const DisplaySelect = ({
  field,
  filledField,
}: DisplayDynamicFieldProps<IFieldSelect, IFilledFieldSelect>) => {
  return (
    <div
      style={{
        display: "flex",
        marginBottom: 15,
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <DisplayFieldTitle field={field} />
      <h4 style={{ fontSize: 14 }}>
        {filledField.values.map((v) => (
          <p style={{ marginRight: 5 }} key={v}>
            {v}
          </p> // Badge WIP
        ))}
      </h4>
    </div>
  );
};

const DisplayFile = ({
  field,
  filledField,
}: DisplayDynamicFieldProps<IFieldFile, IFilledFieldFile>) => {
  return (
    <div style={{ display: "flex", marginBottom: 15, flexDirection: "column" }}>
      <DisplayFieldTitle field={field} />
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {filledField.filesId.map((pictureId) => (
          <ShowPicture pictureId={pictureId} key={pictureId} />
        ))}
      </div>
    </div>
  );
};

const DisplayDrawable = ({
  field,
  filledField,
}: DisplayDynamicFieldProps<IFieldDrawablePicture, IFilledFieldDrawable>) => {
  const { t } = useTranslation();
  const handleDownloadDrawable = () => {
    download(
      window.URL.createObjectURL(
        new Blob([filledField.value], { type: "image/svg" })
      ),
      `${field.placeholder ?? ""}.svg`
    );
  };

  return (
    <div style={{ display: "flex", marginBottom: 15, flexDirection: "column" }}>
      <DisplayFieldTitle field={field} />
      <div
        onClick={handleDownloadDrawable}
        title={t("clickToDownload")}
        style={{
          width: 150,
          height: 200,
          borderRadius: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: 20,
          cursor: "pointer",
        }}
        dangerouslySetInnerHTML={{ __html: filledField.value }}
      />
    </div>
  );
};

const DisplayFileAndDrawable = ({
  field,
  filledField,
}: DisplayDynamicFieldProps<
  IFieldFileAndDrawable,
  IFilledFieldFileAndDrawable
>) => {
  const { t } = useTranslation();
  const handleDownloadDrawable = () => {
    download(
      window.URL.createObjectURL(
        new Blob([filledField.value], { type: "image/svg" })
      ),
      `${field.placeholder ?? ""}.svg`
    );
  };

  return (
    <div style={{ display: "flex", marginBottom: 15, flexDirection: "column" }}>
      <DisplayFieldTitle field={field} />
      <p style={{ fontSize: 12 }}>{field.description}</p>
      <div
        onClick={handleDownloadDrawable}
        title={t("clickToDownload")}
        style={{
          width: 150,
          height: 200,
          borderRadius: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: 20,
          cursor: "pointer",
        }}
        dangerouslySetInnerHTML={{ __html: filledField.value }}
      />
    </div>
  );
};

const DisplaySignature = ({
  field,
  filledField,
}: DisplayDynamicFieldProps<IFieldSignature, IFilledFieldSignature>) => {
  return (
    <div style={{ display: "flex", marginBottom: 15, flexDirection: "column" }}>
      <DisplayFieldTitle field={field} />
      <div
        style={{
          width: 150,
          height: 200,
          borderRadius: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "-20px 20px -60px 20px",
        }}
        dangerouslySetInnerHTML={{ __html: filledField.value }}
      />
    </div>
  );
};

const DisplayDynamicField = ({
  field,
  filledField,
}: DisplayDynamicFieldProps) => {
  const filledFieldAny: any = filledField;
  if (
    (filledField.type === "drawablePicture" && filledField.value === "") ||
    (!filledFieldAny.type && filledFieldAny?.value === "") ||
    (filledField.type === "file" && filledField.filesId.length === 0) ||
    (filledField.type === "fileAndDrawable" && filledField.value === "")
  )
    return null;
  switch (field.type) {
    case "checkbox":
      return <DisplayCheckbox field={field} filledField={filledField as any} />;
    case "select":
      return <DisplaySelect field={field} filledField={filledField as any} />;
    case "file":
      return <DisplayFile field={field} filledField={filledField as any} />;
    case "drawablePicture":
      return <DisplayDrawable field={field} filledField={filledField as any} />;
    case "signature":
      return (
        <DisplaySignature field={field} filledField={filledField as any} />
      );
    case "fileAndDrawable":
      return (
        <DisplayFileAndDrawable
          field={field}
          filledField={filledField as any}
        />
      );
    case "text":
    case "textarea":
    default:
      return (
        <DisplayTextField field={field} filledField={filledField as any} />
      );
  }
};

const DisplayDynamicForm = ({ form, filledForm }: DisplayDynamicFormProps) => {
  const filledFields = filledForm.groups.flatMap((e) => e.fields);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        color: "grey",
      }}
    >
      {form?.groups?.map((g) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          key={g.id}
        >
          <h3
            style={{
              fontSize: 16,
              marginBottom: 16,
              color: "#000",
              fontWeight: 600,
            }}
          >
            {g?.name} :
          </h3>
          {g.fields.map((f) => {
            const temp = filledFields.find((ff) => ff.id === f.id);
            if (!temp) return null;
            return (
              <DisplayDynamicField field={f} filledField={temp} key={f.id} />
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default DisplayDynamicForm;
