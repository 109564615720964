import classNames from "classnames";

type Props = {
  advancement: number;
  toDo?: number;
  inProgress?: number;
  done?: number;
};

const baseStyle = {
  height: 8,
  width: "100%",
  maxWidth: 160,
  display: "flex",
  marginTop: 6,
};

const Advancement = ({ advancement }: Pick<Props, "advancement">) => {
  return (
    <p
      style={{
        fontWeight: "500",
        fontSize: "14px",
        color: "#344054",
        margin: 0,
        paddingLeft: 10,
        minWidth: 31.6,
      }}
    >
      {advancement}%
    </p>
  );
};

const HorizontalPieChart = ({ advancement, toDo, inProgress, done }: Props) => {
  if (toDo === undefined || inProgress === undefined || done === undefined) {
    return (
      <div style={{ display: "flex" }}>
        <div
          style={{
            ...baseStyle,
            backgroundColor: "#EAECF0",
            borderRadius: 4,
          }}
        >
          <div
            style={{
              width: advancement < 6 ? "6px" : `${advancement}%`,
              backgroundColor: advancement === 100 ? "#32D583" : "#151E37",
              borderRadius: 4,
            }}
          />
        </div>
        <Advancement advancement={advancement} />
      </div>
    );
  }
  return (
    <div style={{ display: "flex" }}>
      <div style={baseStyle}>
        <div
          style={{ width: `${done}%`, backgroundColor: "#32D583" }}
          className={classNames("borderLeftRadius", {
            borderRightRadius: inProgress === 0 && toDo === 0,
          })}
        />
        <div
          style={{ width: `${inProgress}%`, backgroundColor: "#2E90FA" }}
          className={classNames({
            borderLeftRadius: done === 0,
            borderRightRadius: toDo === 0,
          })}
        />
        <div
          style={{
            width: `${toDo}%`,
            backgroundColor: "#5925DC",
          }}
          className={classNames("borderRightRadius", {
            borderLeftRadius: inProgress === 0 && done === 0,
          })}
        />
      </div>
      <Advancement advancement={advancement} />
    </div>
  );
};
export default HorizontalPieChart;
