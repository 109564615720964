import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import InputBox from "../Components/InputBox";
import Layout from "../Components/Layout";
import RoundedButton from "../Components/RoundedButton";
import UneditableInputBox from "../Components/UneditableInputBox";
import Api from "../Services/API";
import passwordCriteriaCheck from "../Utils/passwordCriteriaCheck";

const Settings = () => {
  const { t } = useTranslation();
  const [currentPassword, setCurrentPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [companyName, setCompanyName] = useState("");

  useEffect(() => {
    const getUserEnterprise = async () => {
      try {
        const ret = await Api.getUserEnterprise();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        setCompanyName(ret.data.socialReason);
      } catch (error) {
        console.error("getUserEnterprise", error);
      }
    };
    getUserEnterprise();
  }, []);

  const handleSubmit = async () => {
    if (!passwordCriteriaCheck(password1, password2)) return;
    try {
      await Api.editPassword(currentPassword, password1);

      toast(t("passwordModificationSuccess"));
      setCurrentPassword("");
      setPassword1("");
      setPassword2("");
    } catch (error) {
      toast.warn(t("incorrectCurrentPassword"), {
        icon: false,
      });
      console.log(error);
    }
  };

  return (
    <Layout title={t("settings")} subtitle="">
      <h2 style={{ fontSize: 18, marginTop: 24 }}>{t("companyName")}</h2>
      <UneditableInputBox title="" content={companyName} />
      <h2 style={{ fontSize: 18, marginTop: 40 }}>{t("changePassword")}</h2>
      <InputBox
        text={t("currentPassword")}
        type="password"
        large
        value={currentPassword}
        setInput={setCurrentPassword}
      />
      <InputBox
        text={t("newPassword")}
        type="password"
        large
        value={password1}
        setInput={setPassword1}
      />
      <p className="inputBoxInfoBellow">
        {t("heightCharactersMinimumIncludingOneNumberAndOneLetter")}
      </p>
      <InputBox
        text={t("newPasswordConfirmation")}
        type="password"
        large
        value={password2}
        placeholder={t("newPasswordConfirmation")}
        setInput={setPassword2}
      />
      <RoundedButton text={t("modifyPassword")} action={handleSubmit} near />
    </Layout>
  );
};

export default Settings;
