import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { ExtendedMission } from "../Types/extendedMission";
import { Mission } from "../Types/mission.type";
import { stringToStorage } from "./sortingString";
import {
  getActivitiesCountOfStatus,
  getPercentagesOfActivities,
} from "./tableCalculation";

const createExtendedMission = (
  mission: Mission,
  t: TFunction<"translation", undefined>
): ExtendedMission => {
  const activitiesProductives = mission.activities.filter(
    (a) => a.type.productive
  );
  const numberIncidents = activitiesProductives.flatMap((a) =>
    a.serviceProviderResourceHistory.flatMap((h) => h.incidents)
  ).length;
  const numberActivitiesFinished = getActivitiesCountOfStatus(
    activitiesProductives,
    "done"
  );
  const numberActivitiesInProgress = getActivitiesCountOfStatus(
    activitiesProductives,
    "in_progress"
  );
  const numberActivitiesToDo = getActivitiesCountOfStatus(
    activitiesProductives,
    "to_do"
  );
  const numberActivities = activitiesProductives.length;
  const advancement =
    numberActivities === 0
      ? "0"
      : (
          ((numberActivitiesFinished + numberActivitiesInProgress / 2) /
            numberActivities) *
          100
        ).toFixed(0);
  const [percentToDo, percentInProgress, percentFinished] =
    getPercentagesOfActivities(
      numberActivities,
      numberActivitiesToDo,
      numberActivitiesFinished
    );
  return {
    ...mission,
    advancement: stringToStorage(advancement),
    numberActivitiesToDo,
    numberActivitiesInProgress,
    numberActivitiesFinished,
    percentToDo,
    percentInProgress,
    percentFinished,
    statusDisplay:
      advancement === "0"
        ? t("notStarted")
        : advancement === "100"
        ? t("completed")
        : t("inProgress"),
    numberActivities: stringToStorage(numberActivities.toString()),
    numberIncidents: stringToStorage(numberIncidents.toString()),
  };
};

export default createExtendedMission;
