import moment from "moment";

const MomentDateFormat = ({
  date1,
  date2,
}: {
  date1: Date | undefined;
  date2: Date | undefined;
}) => {
  return (
    <span>
      {date1 ? moment(date1).format("DD/MM/Y") : "Nd"}
      {" - "}
      {date2 ? moment(date2).format("DD/MM/Y") : "Nd"}
    </span>
  );
};
export default MomentDateFormat;
