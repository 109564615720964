import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page, pdfjs } from "react-pdf";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import IconRejected from "../Assets/Icons/rejected.svg";
import IconValidated from "../Assets/Icons/validated.svg";
import Api from "../Services/API";
import { CampaignDocument } from "../Types/campaign.type";
import SquareButton from "./SquareButton";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const DisplayPdf = ({ url }: { url: string }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const targetRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      if (targetRef.current) {
        setDimensions({
          width: targetRef.current.offsetWidth,
          height: targetRef.current.offsetHeight,
        });
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const onDocumentLoadSuccess = ({ numPages }: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setNumPages(numPages);
  };

  const renderError = () => (
    <div>
      <p>{t("previewUnavailable")}</p>
    </div>
  );

  return (
    <div style={{ marginBottom: 15, width: "100%" }}>
      {(numPages ?? 1) > 1 && (
        <div style={{ gap: 10 }}>
          <p style={{ margin: 0 }}>
            {t("page")} {pageNumber} {t("outOf")} {numPages}
          </p>
          <button
            className="smallButton"
            onClick={() => pageNumber > 1 && setPageNumber(pageNumber - 1)}
          >
            {t("previous")}
          </button>
          <button
            className="smallButton"
            onClick={() =>
              numPages !== null &&
              pageNumber < numPages &&
              setPageNumber(pageNumber + 1)
            }
          >
            {t("next")}
          </button>
        </div>
      )}
      <div ref={targetRef} style={{ width: "100%" }}>
        <Document
          file={url}
          onLoadSuccess={onDocumentLoadSuccess}
          error={renderError()}
        >
          <Page
            width={dimensions.width}
            height={dimensions.height}
            pageNumber={pageNumber}
          />
        </Document>
      </div>
    </div>
  );
};

const DisplayDocument = ({
  document,
  url,
}: {
  document: CampaignDocument;
  url: string;
}) => {
  const { t } = useTranslation();
  if (document.type === "image/jpeg" || document.type === "image/png")
    return <img src={url} alt="" style={{ width: "100%" }} />;
  if (document.type === "application/pdf") return <DisplayPdf url={url} />;
  return (
    <div style={{ marginTop: 16 }}>
      {t("previewFile")} {document.name} {t("impossible")}
    </div>
  );
};

const ColoredTextIcon = ({
  green,
  type,
}: {
  green?: boolean;
  type: string;
}) => {
  const { t } = useTranslation();
  return (
    <p
      style={{
        color: green ? "#12B76A" : "#F04438",
        marginBottom: 0,
        position: "relative",
        paddingLeft: 24,
      }}
    >
      {green
        ? `${t("youAcceptedThis")} ${type}`
        : `${t("youRejectedThis")} ${type}, ${t(
            "weWillGetBackToYouWithANew"
          )}.`}
      <img
        src={green ? IconValidated : IconRejected}
        alt=""
        className="enforceMargin"
        style={{
          position: "absolute",
          top: 3.5,
          left: 0,
        }}
      />
    </p>
  );
};

const DisplayDocumentBloc = ({
  document,
  noDateTime,
}: {
  document: CampaignDocument;
  noDateTime?: boolean;
}) => {
  const [accept, setAccept] = useState(false);
  const [reject, setReject] = useState(false);
  const [input, setInput] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const url = `https://${
    window.location.href.includes("https://client.covworld.fr")
      ? "covworld-dev"
      : "covworld-staging"
  }.s3.eu-west-3.amazonaws.com/${document.awsKey}`;
  const type =
    document.category === "bat" ? "BAT" : t("quote").toLocaleLowerCase();

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const unusedFunction = () => {};
  const handleAccept = () => {
    setAccept(true);
    setReject(false);
  };
  const handleReject = () => {
    setAccept(false);
    setReject(true);
  };
  const handleReview = async () => {
    if (reject === true && input.length === 0) {
      toast.warning(t("pleaseFillTheReason"), {
        icon: false,
      });
      return;
    }
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      const ret: any = await Api.reviewDocument(
        document.id,
        accept,
        accept ? "" : input
      );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (ret.status === 200) {
        navigate(
          `${window.location.pathname}?action=${
            document.category === "bat" ? "validate_bat" : "informations"
          }`
        );
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div>
        {document.category === "bat" && (
          <div style={{ color: "black", fontSize: 18, fontWeight: 700 }}>
            {t("goodToTake")} {document.name} {document.status}
          </div>
        )}
        {document.status === "waiting" ? (
          <>
            <p style={{ marginBottom: -8 }}>
              {t("pleaseValidateOrInvalidateThe")} {type}
            </p>
            <a href={`${url}?force=true`}>
              <SquareButton
                color="white"
                text={`${t("download")} ${t("the")} ${type}`}
                action={unusedFunction}
              />
            </a>
            <div style={{ display: "flex", gap: 16 }}>
              <SquareButton
                color={accept === false ? "green" : "grey"}
                text={`${t("accept")} ${t("the")} ${type}`}
                action={handleAccept}
              />
              <SquareButton
                color={reject === false ? "red" : "grey"}
                text={`${t("refuse")} ${t("the")} ${type}`}
                action={handleReject}
              />
            </div>
            {accept && (
              <SquareButton
                color="green"
                text={`${t("confirmTheAcceptanceOfThe")} ${type}`}
                action={handleReview}
              />
            )}
            {reject && (
              <div>
                <p style={{ fontSize: 16 }}>
                  {t("confirmTheRefusalOfThe")} {type}
                </p>
                <p style={{ color: "#667085" }}>
                  {t("pleaseExplainTheReasonForRefusingThe")} {type}.{" "}
                  {t("weWillGetBackToYouShortly")}
                </p>
                <textarea
                  className="inputBox"
                  onChange={(e) => setInput(e.target.value)}
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    height: 150,
                  }}
                />
                <SquareButton
                  color="red"
                  text={`${t("confirmTheRefusalOfThe")} ${type}`}
                  action={handleReview}
                />
              </div>
            )}
          </>
        ) : (
          <div>
            {document.status === "validated" && (
              <ColoredTextIcon type={type} green />
            )}
            {document.status === "rejected" && <ColoredTextIcon type={type} />}
            <a href={`${url}?force=true`}>
              <SquareButton
                color="white"
                text={`${t("download")} ${t("the")} ${t(
                  "document"
                ).toLocaleLowerCase()}`}
                action={unusedFunction}
              />
            </a>
            {document.status === "rejected" && (
              <div className="oldInput">{document.text}</div>
            )}
            {noDateTime ? (
              <div style={{ height: 20 }} />
            ) : (
              <p style={{ color: "#667085", fontSize: 12 }}>
                {t("timestamp")} :{" "}
                {moment(document.validatedAt).format(
                  `DD/MM/Y ${t("at")} HH:mm`
                )}
              </p>
            )}
          </div>
        )}
      </div>
      <DisplayDocument document={document} url={url} />
    </>
  );
};

export default DisplayDocumentBloc;
