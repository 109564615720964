const RoutesPath = {
  settings: "/settings",
  actions: "/actions",
  contact: "/contact",
  search: "/search",
  campaigns: "/campaign",
  campaign: "/campaign/:campaignId",
  mission: "/campaign/:campaignId/mission/:missionId",
  activity: "/campaign/:campaignId/mission/:missionId/activity/:activityId",
  home: "/",
  everything: "*",
  resetPassword: "resetpassword",
  newPassword: "newpassword",
  register: "/register",
  quote: "/quote",
};

export default RoutesPath;
