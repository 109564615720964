import Axios from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Api from "../Services/API";
import SquareButton from "./SquareButton";

const baseStyle = {
  flex: 1,
  display: "flex",
  // eslint-disable-next-line @typescript-eslint/prefer-as-const
  flexDirection: "column" as "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const MyDropzone = ({
  isQuote,
  startUploadDocumentsQuoteId,
  cleanup,
}: {
  isQuote?: boolean;
  startUploadDocumentsQuoteId?: number;
  cleanup?: () => void;
}) => {
  const { campaignId } = useParams();
  const [myFiles, setMyFiles] = useState<File[]>([]);
  const { t } = useTranslation();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      for (const file of acceptedFiles) {
        const reader = new FileReader();

        reader.addEventListener("abort", () =>
          console.log("file reading was aborted")
        );
        // eslint-disable-next-line unicorn/prefer-add-event-listener
        reader.onerror = () => console.log("file reading has failed");
        reader.addEventListener("load", () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result;
          console.log(binaryStr);
        });
        reader.readAsArrayBuffer(file);
      }
      setMyFiles([...myFiles, ...acceptedFiles]);
    },
    [myFiles]
  );

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  const uploadFile = async (presignedUrl: string, key: string, file: File) => {
    console.info("uploadFile launch:", presignedUrl);
    toast(`${t("file")} ${file.name} ${t("startingToSend")}`);
    try {
      const result: any = await Axios.put(presignedUrl, file);
      await Api.addToDatabase(
        file.name,
        key,
        campaignId ?? "0",
        file.type,
        startUploadDocumentsQuoteId
      );
      toast.success(`${t("file")} ${file.name} ${t("sentSuccessfully")}`);
      removeFile(file);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
      return result.data;
    } catch (error: any) {
      console.error("uploadFile error:", error);
      toast.warning(`${t("failureToSend")} ${file.name}`, {
        icon: false,
      });
    }
    return null;
  };

  const removeFile = (file: File) => {
    console.log("removeFile file.name", file.name);
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
    return null;
  };

  const files = myFiles.map((file: any) => (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    <li key={file.path}>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
      {file.path} - {(file.size / 1_000_000).toFixed(1)} Mo{" "}
      <span
        style={{ color: "red", cursor: "pointer" }}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        onClick={() => removeFile(file)}
      >
        {t("deleteFile")}
      </span>
    </li>
  ));

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  // eslint-disable-next-line unicorn/consistent-function-scoping, @typescript-eslint/require-await
  const handleUpload = async () => {
    console.log("upload");
    if (myFiles.length > 0) {
      console.log(myFiles.length, "files to send");
    }
    try {
      // eslint-disable-next-line @typescript-eslint/require-await
      myFiles.map(async (file) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        const ret = await Api.getAwsPresignedUrl(file.name, file.type);
        console.log("ret", ret.data);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        uploadFile(ret.data.presignedUrl, ret.data.key, file);
        return null;
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const uploadQuoteDocuments = async () => {
      if (startUploadDocumentsQuoteId !== 0 && cleanup) {
        await handleUpload();
        cleanup();
      }
    };
    uploadQuoteDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startUploadDocumentsQuoteId]);

  return (
    <div>
      <p>{t("pleaseDropTheCreations")}</p>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>{t("dragAndDropFilesHereOrClickToSelectFiles")}</p>
      </div>
      {files.length > 0 && (
        <aside>
          <ul>{files}</ul>
          {!isQuote && (
            <SquareButton
              text={t("confirmAndSendFiles")}
              action={handleUpload}
              color="green"
            />
          )}
        </aside>
      )}
    </div>
  );
};

export default MyDropzone;
