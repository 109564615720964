/* eslint-disable react/no-unstable-nested-components */
import {
  ColumnFiltersState,
  createColumnHelper,
  getCoreRowModel,
  getFacetedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import arrowLeft from "../Assets/Icons/arrow_left.svg";
import DebouncedInput from "../Components/DebouncedInput";
import DisplayDocumentBloc from "../Components/DisplayDocumentBloc";
import DoughnutChart from "../Components/DoughnutChart";
import Downloader from "../Components/Downloader";
import GlowingBox from "../Components/GlowingBox";
import HorizontalPieChart from "../Components/HorizontalPieChart";
import Layout from "../Components/Layout";
import MainTable from "../Components/MainTable";
import MomentDateFormat from "../Components/MomentDateFormat";
import MyDropzone from "../Components/MyDropzone";
import PieChart from "../Components/PieChart";
import RatingModal from "../Components/RatingModal";
import SelectBox from "../Components/SelectBox";
import SquareButton from "../Components/SquareButton";
import { UserContext, UserInterface } from "../Context/UserContext";
import RoutesPath from "../Router/RoutesPath";
import {
  Campaign as CampaignType,
  CampaignDocument,
  CampaignStatus,
  DocumentCategory,
  DocumentStatus,
} from "../Types/campaign.type";
import { ExtendedMission } from "../Types/extendedMission";
import createExtendedMission from "../Utils/createExtendedMission";
import { storageToNumber } from "../Utils/sortingString";
import { CampaignTab, tabClassName } from "../Utils/tabSelect";
import { campaignStatusRecord } from "./Campaigns";

const MapMe = ({
  documents: rawDocuments,
  category,
  status,
  title,
}: {
  documents?: CampaignDocument[];
  category?: DocumentCategory;
  status?: DocumentStatus;
  title?: string;
}) => {
  if (!rawDocuments) return null;
  const documents = rawDocuments.filter(
    (d) =>
      (!category || d.category === category) && (!status || d.status === status)
  );
  return (
    <div>
      {title && documents.length > 0 && (
        <h3 style={{ fontWeight: 600, fontSize: 18, marginBottom: 15 }}>
          {title}
        </h3>
      )}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "400px 1fr",
          maxWidth: 1250,
          gap: 16,
        }}
      >
        {documents.map((document) => (
          <DisplayDocumentBloc document={document} key={document.id} />
        ))}
      </div>
    </div>
  );
};

const DisplayCreations = ({ documents }: { documents: CampaignDocument[] }) => {
  const { t } = useTranslation();
  if (documents.length === 0) return null;
  return (
    <>
      <h3 style={{ fontWeight: 600, fontSize: 18, marginBottom: 15 }}>
        {t("creations")}
      </h3>
      <div>
        {documents.map((document) => (
          <div
            style={{
              backgroundColor: "white",
              margin: "12px 0px",
              border: "1px solid #EAECF0",
              padding: "16px 24px",
              borderRadius: 8,
              fontSize: 14,
              gap: 10,
            }}
            key={document.id}
          >
            <p style={{ margin: 0 }}>{document.name}</p>
            <p style={{ color: "#667085", margin: "7px 0px" }}>
              {t("timestamp")} :{" "}
              {moment(document.createdAt).format("DD/MM/Y à HH:mm")}
            </p>
            <a
              style={{ textDecoration: "none" }}
              href={`https://${
                window.location.href.includes("https://client.covworld.fr")
                  ? "covworld-dev"
                  : "covworld-staging"
              }.s3.eu-west-3.amazonaws.com/${document.awsKey}?force=true`}
            >
              {t("downloadTheCreation")}
            </a>
          </div>
        ))}
      </div>
    </>
  );
};

const CampaignInformations = ({
  campaign,
}: {
  campaign: CampaignType | undefined;
}) => {
  const { t } = useTranslation();
  if (!campaign) return null;
  const { documents, description } = campaign;
  const creation = documents.filter(
    (document) => document.category === "creation"
  );
  return (
    <div>
      {description.length > 0 && (
        <h3 style={{ fontWeight: 600, fontSize: 18, marginBottom: 15 }}>
          {t("description")}
        </h3>
      )}
      <div
        style={{ fontSize: 14 }}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <MapMe
        documents={documents}
        category="bat"
        status="validated"
        title={t("bonATirer")}
      />
      <DisplayCreations documents={creation} />
      <MapMe
        documents={documents}
        category="quote"
        status="validated"
        title={t("quote")}
      />
    </div>
  );
};

const columnHelper = createColumnHelper<ExtendedMission>();

const Campaign = () => {
  const { campaignId } = useParams();
  const { data: rawData } = useContext(UserContext) as UserInterface;
  console.log(rawData);
  const rawDataCampaign = rawData.find((c) => c.id.toString() === campaignId);
  const [rawDataMissionExtended, setRawDataMissionExtended] = useState<
    ExtendedMission[]
  >([]);
  const [data, setData] = useState<ExtendedMission[]>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const { t } = useTranslation();
  const [filterMode, setFilterMode] = useState<string>(t("all"));
  const [loading, setIsLoading] = useState<boolean>(true);
  const [percentCampaign, setPercentCampaign] = useState<number>(0);
  const [tabSelected, setTabSelected] = useState<CampaignTab>("missions");
  const [pieData, setPieData] = useState<[number, number, number]>([0, 0, 0]);
  const [openModal, setOpenModal] = useState<boolean | number>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!rawDataCampaign) return;
    const temp = rawDataCampaign.missions.map((mission) =>
      createExtendedMission(mission, t)
    );
    const temp2 = temp.reduce(
      (acc, mission) =>
        acc +
        mission.numberActivitiesFinished +
        mission.numberActivitiesInProgress / 2,
      0
    );
    const percentCampaignCalculation =
      temp.flatMap((m) => m.activities).length === 0
        ? 0
        : temp2 / temp.flatMap((m) => m.activities).length;
    setPercentCampaign(+(percentCampaignCalculation * 100).toFixed(0));
    setPieData([
      temp.reduce((acc, m) => acc + m.numberActivitiesToDo, 0),
      temp.reduce((acc, m) => acc + m.numberActivitiesInProgress, 0),
      temp.reduce((acc, m) => acc + m.numberActivitiesFinished, 0),
    ]);
    setRawDataMissionExtended(temp);
    setData(temp);
    setIsLoading(false);
  }, [rawDataCampaign, t]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: t("mission"),
        cell: (props) => (
          <>
            <div>{props.getValue()}</div>
            <div style={{ color: "#667085" }}>
              <MomentDateFormat
                date1={props.row.original.startDate}
                date2={props.row.original.endDate}
              />
            </div>
          </>
        ),
      }),
      columnHelper.accessor("location", {
        header: t("location"),
        cell: (info) => info.renderValue(),
      }),
      columnHelper.accessor("statusDisplay", {
        header: t("status"),
        cell: (info) => <GlowingBox text={info.renderValue()} />,
      }),
      columnHelper.accessor("advancement", {
        header: t("progresPercentage"),
        cell: (props) => {
          return (
            <HorizontalPieChart
              toDo={props.row.original.percentToDo}
              inProgress={props.row.original.percentInProgress}
              done={props.row.original.percentFinished}
              advancement={+props.row.original.advancement}
            />
          );
        },
      }),
      columnHelper.accessor("numberActivities", {
        header: t("activities"),
        cell: (info) => {
          return (
            <div style={{ paddingLeft: 26 }}>
              {storageToNumber(info.renderValue())}
            </div>
          );
        },
      }),
      columnHelper.accessor("numberIncidents", {
        header: t("incidents"),
        cell: (info) => (
          <GlowingBox text={storageToNumber(info.renderValue()).toString()} />
        ),
      }),
      columnHelper.accessor("id", {
        header: () => "",
        cell: (props) => (
          <Downloader
            missionId={props.row.original.id}
            missionName={props.row.original.name}
          />
        ),
        enableSorting: false,
      }),
    ],
    [t]
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  useEffect(() => {
    table.setSorting([{ id: "advancement", desc: false }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getState().columnFilters[0]?.id]);

  useEffect(() => {
    if (window.location.href.includes("validate_quote"))
      setTabSelected("quote");
    if (window.location.href.includes("submit_creation"))
      setTabSelected("creations");
    if (window.location.href.includes("validate_bat")) setTabSelected("bat");
    if (window.location.href.includes("informations"))
      setTabSelected("informations");
  }, []);

  if (!loading && !rawDataCampaign) {
    return (
      <Layout title={t("campaign")} subtitle="">
        <h1>{t("thisCampaignCannotBeFound")}</h1>
      </Layout>
    );
  }

  return (
    <Layout>
      <div style={{ display: "flex" }}>
        <img
          src={arrowLeft}
          alt="Icon"
          style={{ marginRight: 17, cursor: "pointer" }}
          onClick={() => navigate(RoutesPath.campaigns)}
        />
        <h1 style={{ margin: 0 }}>{rawDataCampaign?.name}</h1>
        <div style={{ margin: 6 }}>
          {rawDataCampaign?.status && (
            <GlowingBox
              text={campaignStatusRecord[rawDataCampaign?.status ?? "to_sign"]}
            />
          )}
        </div>
      </div>
      <h2 style={{ marginTop: 4, color: "#667085", fontSize: 16 }}>
        <MomentDateFormat
          date1={rawDataCampaign?.startDate}
          date2={rawDataCampaign?.endDate}
        />
      </h2>
      <PieChart percentCampaign={percentCampaign} />
      <div className="tabSelect">
        {rawDataCampaign?.documents.some(
          (document) =>
            document.status === "waiting" && document.category === "quote"
        ) && (
          <div
            className={tabClassName("quote", tabSelected)}
            onClick={() => setTabSelected("quote")}
          >
            {t("validationOfTheQuote")}
          </div>
        )}
        <div
          className={tabClassName("creations", tabSelected)}
          onClick={() => setTabSelected("creations")}
        >
          {t("dropTheCreations")}
        </div>
        {rawDataCampaign?.documents.some(
          (document) => document.category === "bat"
        ) && (
          <div
            className={tabClassName("bat", tabSelected)}
            onClick={() => setTabSelected("bat")}
          >
            {t("validateTheBAT")}
          </div>
        )}
        <div
          className={tabClassName("missions", tabSelected)}
          onClick={() => setTabSelected("missions")}
        >
          {t("missions")} <GlowingBox text={rawDataMissionExtended.length} />
        </div>
        <p
          className={tabClassName("informations", tabSelected)}
          onClick={() => setTabSelected("informations")}
        >
          {t("informations")}
        </p>
        {rawDataCampaign?.documents.some(
          (document) => document.category === "invoice"
        ) && (
          <p
            className={tabClassName("invoices", tabSelected)}
            onClick={() => setTabSelected("invoices")}
          >
            {t("invoice")}
          </p>
        )}
      </div>
      {tabSelected === "quote" ? (
        <MapMe
          documents={rawDataCampaign?.documents}
          category="quote"
          status="waiting"
        />
      ) : tabSelected === "creations" ? (
        <MyDropzone />
      ) : tabSelected === "bat" ? (
        <MapMe documents={rawDataCampaign?.documents} category="bat" />
      ) : tabSelected === "missions" ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 16,
            }}
          >
            <SelectBox
              boxes={[
                t("all"),
                t("notStarted"),
                t("inProgress"),
                t("finished_singular"),
              ]}
              filterMode={filterMode}
              setFilterMode={setFilterMode}
              setData={setData}
              rawDataExtended={rawDataMissionExtended}
            />
            <DebouncedInput
              value={globalFilter ?? ""}
              onChange={(value) => setGlobalFilter(String(value))}
              placeholder={t("searchAMission")}
              small
            />
          </div>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
          <MainTable table={table} path="mission/" />
          <div style={{ display: "flex" }}>
            <DoughnutChart pieData={pieData} />
            {rawDataCampaign?.reviewCompleted === false &&
              rawDataCampaign?.status === CampaignStatus.done && (
                <div
                  style={{
                    alignItems: "start",
                    margin: "4px auto",
                  }}
                >
                  <SquareButton
                    color="purple"
                    text={t("satisfactionQuestionnaire")}
                    action={() =>
                      setOpenModal(campaignId ? +campaignId : false)
                    }
                  />
                </div>
              )}
          </div>
          <RatingModal openModal={openModal} setOpenModal={setOpenModal} />
        </>
      ) : tabSelected === "informations" ? (
        <CampaignInformations campaign={rawDataCampaign} />
      ) : (
        <div>
          {rawDataCampaign?.documents
            .filter((d) => d.category === "invoice")
            .map((document) => (
              <DisplayDocumentBloc
                document={document}
                key={document.id}
                noDateTime
              />
            ))}
        </div>
      )}
    </Layout>
  );
};

export default Campaign;
