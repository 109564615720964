/* eslint-disable react/no-unstable-nested-components */
import "moment/locale/fr";

import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { t as tt } from "i18next";
import moment from "moment";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import arrowLeft from "../Assets/Icons/arrow_left.svg";
import IconLink from "../Assets/Icons/link.svg";
import {
  DisplayIncidentReports,
  DisplayInterventionReports,
} from "../Components/DisplayForm/DisplayActivityReports";
import GlowingBox from "../Components/GlowingBox";
import Layout from "../Components/Layout";
import MainTable from "../Components/MainTable";
import { UserContext, UserInterface } from "../Context/UserContext";
import RoutesPath from "../Router/RoutesPath";
import {
  ActivityStatus,
  LocationHistory,
  LocationHistoryAction,
} from "../Types/activity.type";
import { ActionTab, tabClassName } from "../Utils/tabSelect";

export const activityStatusRecord: Record<ActivityStatus, string> = {
  in_progress: tt("inProgress"),
  to_do: tt("toDo"),
  done: tt("finished_singular"),
};

export const locationHistoryActionRecord: Record<
  LocationHistoryAction,
  string
> = {
  start_mission: tt("missionStarted"),
  update_mission: tt("update"),
  finish_later: tt("toBeFinishedLater"),
  report_sent: tt("reportSent"),
  incident: tt("incident"),
};

type LocationHistoryLight = Pick<
  LocationHistory,
  | "id"
  | "lat"
  | "lng"
  | "action"
  | "serviceProviderResourceId"
  | "activityServiceProviderResourceHistoryId"
  | "createdAt"
>;

const columnHelper = createColumnHelper<LocationHistoryLight>();

const Activity = () => {
  const { campaignId, missionId, activityId } = useParams();
  const { t } = useTranslation();
  const [tabSelected, setTabSelected] =
    useState<ActionTab>("interventionReport");
  const navigate = useNavigate();

  const { data: campaignsData } = useContext(UserContext) as UserInterface;
  const campaign = campaignsData.find((c) => c.id.toString() === campaignId);
  const mission = campaign?.missions.find((m) => m.id.toString() === missionId);
  const activity = mission?.activities.find(
    (m) => m.id.toString() === activityId
  );
  const incidentsCount =
    activity?.serviceProviderResourceHistory.flatMap((h) => h.incidents)
      .length ?? 0;

  const data = useMemo(() => {
    return (
      activity?.serviceProviderResourceHistory.flatMap(
        (h) => h.locationHistory
      ) ?? []
    );
  }, [activity]);

  const dataIncidents = useMemo(() => {
    return data.filter((a) => a.action === "incident");
  }, [data]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("createdAt", {
        header: t("timestamp"),
        cell: (props) => (
          <div>
            {moment(props.row.original.createdAt).locale("fr").format("LLL")}
          </div>
        ),
        enableSorting: false,
      }),
      columnHelper.accessor("id", {
        header: `${t("geolocation")} `,
        cell: (props) =>
          props.row.original.lng === 0 ? (
            <>{t("notAvailable")}</>
          ) : (
            <a
              style={{
                display: "flex",
                color: "#1C4494",
                textDecoration: "none",
              }}
              target="_blank"
              href={`https://www.google.com/maps/place/${props.row.original.lat},${props.row.original.lng}`}
              rel="noreferrer"
            >
              <div>{props.row.original.lat}</div>
              <div style={{ marginLeft: 6, marginRight: 8 }}>
                {props.row.original.lng}
              </div>
              <img src={IconLink} alt="" />
            </a>
          ),
        enableSorting: false,
      }),
      columnHelper.accessor("action", {
        header: t("status"),
        cell: (props) => (
          <GlowingBox
            text={locationHistoryActionRecord[props.row.original.action]}
          />
        ),
        enableSorting: false,
      }),
    ],
    [t]
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const tableIncidents = useReactTable({
    data: dataIncidents,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Layout title="" subtitle="">
      <div style={{ display: "flex" }}>
        <img
          src={arrowLeft}
          alt="Icon"
          style={{ marginRight: 17, cursor: "pointer" }}
          onClick={() =>
            navigate(
              RoutesPath.mission
                .replace(":campaignId", campaign?.id.toString() ?? "")
                .replace(":missionId", mission?.id.toString() ?? "")
            )
          }
        />
        <h1 style={{ margin: 0 }}>{activity?.name}</h1>
        <div style={{ margin: 6 }}>
          {activity?.status && (
            <GlowingBox text={activityStatusRecord[activity?.status ?? ""]} />
          )}
        </div>
      </div>
      <h2 style={{ marginTop: 4, color: "#98A2B3", fontSize: 13 }}>
        {campaign?.name}
        {" / "}
        {mission?.name}
        {" / "}
        <span style={{ color: "#1C4494" }}>{activity?.name}</span>
      </h2>
      <div>
        {incidentsCount !== 0 && (
          <div className="tabSelect">
            <p
              className={tabClassName("interventionReport", tabSelected)}
              onClick={() => setTabSelected("interventionReport")}
            >
              {t("interventionReport")}
            </p>
            <div
              className={tabClassName("incidents", tabSelected)}
              onClick={() => setTabSelected("incidents")}
            >
              {t("incidents")}
              <GlowingBox text={incidentsCount} />
            </div>
          </div>
        )}
      </div>
      <div>
        {tabSelected === "interventionReport" && (
          <>
            <h2 style={{ marginTop: 30, fontWeight: 600 }}>
              {t("description")}
            </h2>
            {activity?.description && (
              <div
                style={{ fontSize: 14 }}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: activity.description }}
              />
            )}
            <DisplayInterventionReports activity={activity} />
            {data.length > 0 && (
              <div>
                <h2 style={{ marginTop: 30, fontWeight: 600 }}>
                  {t("timestampAndGPSPositioning")}
                </h2>
                {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                <MainTable table={table} />
              </div>
            )}
          </>
        )}
        {tabSelected === "incidents" && (
          <>
            <DisplayIncidentReports activity={activity} />
            <h2 style={{ marginTop: 30, fontWeight: 600 }}>
              {t("timestampAndGPSPositioning")}{" "}
              {t("incident").toLocaleLowerCase()}
            </h2>
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
            <MainTable table={tableIncidents} />
          </>
        )}
      </div>
    </Layout>
  );
};
export default Activity;
