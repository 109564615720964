import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Layout from "../Components/Layout";
import UneditableInputBox from "../Components/UneditableInputBox";
import Api from "../Services/API";

type ContactType = {
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  phone: string;
};

const Contact = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState<ContactType>({
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  useEffect(() => {
    const getContact = async () => {
      try {
        const ret = await Api.getContact();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        setContact(ret.data.contact);
      } catch (error) {
        console.error("getContact", error);
      }
      setLoading(false);
    };
    setLoading(true);
    getContact();
  }, []);

  return (
    <Layout title={t("yourCovworldContact")}>
      <div>
        {!loading ? (
          <>
            <UneditableInputBox
              title="Nom / Prénom"
              content={`${contact.firstName} ${contact.lastName}`}
            />
            <UneditableInputBox
              title={t("emailAddress")}
              content={contact.email}
            />
            <UneditableInputBox
              title={t("phoneNumber")}
              content={contact.phone}
            />
          </>
        ) : null}
      </div>
    </Layout>
  );
};

export default Contact;
