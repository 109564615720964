import React from "react";
import { Route, Routes } from "react-router-dom";

import useAuthStatus from "../Hooks/useAuthStatus";
import Actions from "../Pages/Actions";
import Activity from "../Pages/Activity";
import Campaign from "../Pages/Campaign";
import Campaigns from "../Pages/Campaigns";
import Contact from "../Pages/Contact";
import Login from "../Pages/Login";
import Mission from "../Pages/Mission";
import NewPassword from "../Pages/NewPassword";
import PageNotFound from "../Pages/PageNotFound";
import Quote from "../Pages/Quote";
import Register from "../Pages/Register";
import ResetPassword from "../Pages/ResetPasswod";
import Search from "../Pages/Search";
import Settings from "../Pages/Settings";
import RoutesPath from "./RoutesPath";

// protecting all Routes by checking there is a valid jwt
const Router = () => {
  // the useAuthStatus load the information in the usecontext that stores the information
  const isAuthenticated = useAuthStatus();
  console.log("isAuthenticated", isAuthenticated);

  if (isAuthenticated === undefined) return null;
  if (!isAuthenticated)
    return (
      <Routes>
        <Route path={RoutesPath.everything} element={<Login />} />
        <Route path={RoutesPath.register} element={<Register />} />
        <Route path={RoutesPath.resetPassword} element={<ResetPassword />} />
        <Route path={RoutesPath.newPassword} element={<NewPassword />} />
      </Routes>
    );
  return (
    <Routes>
      <Route path={RoutesPath.campaigns} element={<Campaigns />} />
      <Route path={RoutesPath.home} element={<Campaigns />} />
      <Route path={RoutesPath.campaign} element={<Campaign />} />
      <Route path={RoutesPath.mission} element={<Mission />} />
      <Route path={RoutesPath.activity} element={<Activity />} />
      <Route path={RoutesPath.actions} element={<Actions />} />
      <Route path={RoutesPath.contact} element={<Contact />} />
      <Route path={RoutesPath.settings} element={<Settings />} />
      <Route path={RoutesPath.search} element={<Search />} />
      <Route path={RoutesPath.quote} element={<Quote />} />
      <Route path={RoutesPath.everything} element={<PageNotFound />} />
    </Routes>
  );
};

export default Router;
