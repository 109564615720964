import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      loading: "Loading",
      youDidNotRealizeAnyCampaignYet: "You have not yet realized any campaign",
      register: "Register",
      login: "Login",
      passwordForgotten: "Forgot Password",
      password: "Password",
      connectToYourClientSpace: "Connect to your client space",
      emailAddress: "Email Address",
      incorrectPasswordOrEmail: "Incorrect password or email",
      ifTheUserExistsEmailSent: "If the user exists, an email has been sent",
      ifTheUserExistsEmailSentToTheProvidedAddressIfYouHaveNotReceivedAConfirmationEmailPleaseCheckYourSpamFolder:
        "If the user exists, an email has been sent to the provided address. If you have not received a confirmation email, please check your spam folder.",
      resetPassword: "Reset Password",
      resendEmail: "Resend Email",
      searchForFieldInActivityReports: "Search for a field in activity reports",
      search: "Search",
      searchResults: "Search Results",
      noResults: "No Results",
      campaign: "Campaign",
      mission: "Mission",
      activity: "Activity",
      companyName: "Company Name",
      changePassword: "Change Password",
      currentPassword: "Current Password",
      newPassword: "New Password",
      newPasswordConfirmation: "New Password Confirmation",
      passwordModificationSuccess: "Password modified successfully",
      incorrectCurrentPassword: "Incorrect current password",
      heightCharactersMinimumIncludingOneNumberAndOneLetter:
        "8 characters minimum including one number and one letter",
      modifyPassword: "Modify Password",
      signUp: "Sign Up",
      firstName: "First Name",
      lastName: "Last Name",
      company: "Company",
      email: "Email",
      phone: "Phone",
      confirmPassword: "Confirm Password",
      thePasswordMustContainAtLeast8CharactersOneLetterAndOneNumber:
        "The password must contain at least 8 characters, one letter, and one number",
      thisFieldIsRequired: "This field is required",
      passwordNotMeetingRequirements:
        "The password does not meet the requirements",
      passwordsDoNotMatch: "Passwords do not match",
      errorOrEmailAlreadyUsed: "Error or email already used",
      registrationSuccessful: "Registration successful",
      error: "Error",
      requestNumber: "Request Number",
      requestDate: "Request Date",
      name: "Name",
      progress: "Progress",
      toBeProcessed: "To be processed",
      completed: "Completed",
      completed_plural: "Completed",
      newQuote: "New Quote",
      newRequest: "New Request",
      send: "Send",
      comment: "Comment",
      requestedPrestations: "Requested Services",
      others: "Others",
      other: "Other",
      design: "Design",
      projectManagement: "Project Management",
      installation: "Installation",
      printing: "Printing",
      deploymentDate: "Deployment Date",
      deploymentPlace: "Deployment Place",
      tarpaulin: "Tarpaulin",
      adhesive: "Adhesive",
      paper: "Paper",
      typeOfAdhesiveDesired: "Type of adhesive desired",
      typeOfInstallationDesired: "Type of installation desired",
      signArea: "Sign Area",
      signNumber: "Sign Number",
      microperforated: "Microperforated",
      solidAdhesive: "Solid Adhesive",
      easyApplicationAdhesiveYupoTako: "Easy application adhesive (Yupo Tako)",
      windowShopArea: "Shop Window Area",
      windowShopNumber: "Number of Shop Windows",
      back: "Back",
      completeFlanks: "Complete Flanks",
      carDoors: "Car Doors",
      fullCovering: "Full Covering",
      areasToCoverOnTheVehicle: "Areas to cover on the vehicle",
      numberOfVehicles: "Number of Vehicles",
      bus: "Bus",
      heavyTruck: "Heavy Truck",
      car: "Car",
      typeOfVehicle: "Type of Vehicle",
      campaignName: "Campaign Name",
      sign: "Sign",
      windowShop: "Shop Window",
      vehicles: "Vehicles",
      campaignType: "Campaign Type",
      newQuoteRequest: "New Quote Request",
      quoteRequest: "Quote Request",
      error404PageNotFound: "Error 404 - Page not found",
      youWillBeRedirectedAutomaticallyToTheHomePage:
        "You will be redirected automatically to the home page",
      returnToHomePageNow: "Return to the home page now",
      theLinkExpired: "The link has expired",
      passwordAreNotIdentical: "Passwords are not identical",
      thePasswordHasBeen: "The password has been",
      successfullyChanged: "successfully changed",
      goToTheLoginPage: "Go to the login page",
      description: "Description",
      status: "Status",
      incidents: "Incidents",
      incident: "Incident",
      productive: "Productive",
      thisCampaignCannotBeFound: "This campaign cannot be found",
      yourCovworldContact: "Your Covworld contact",
      covworldContact: "Covworld contact",
      phoneNumber: "Phone Number",
      campaigns: "Campaigns",
      missions: "Missions",
      activities: "Activities",
      progresPercentage: "Progress %",
      followUpOfYourCampaigns: "Follow-up of your campaigns",
      location: "Location",
      validationOfTheQuote: "Quote Validation",
      dropTheCreations: "Drop the creations",
      validateTheBAT: "Validate the BAT",
      informations: "Information",
      invoice: "Invoice",
      searchAMission: "Search a mission",
      satisfactionQuestionnaire: "Satisfaction Questionnaire",
      bonATirer: "Bon à tirer",
      quote: "Quote",
      creations: "Creations",
      timestamp: "Timestamp",
      downloadTheCreation: "Download the creation",
      interventionReport: "Intervention Report",
      timestampAndGPSPositioning: "Timestamp and GPS Positioning",
      geolocation: "Geolocation",
      notAvailable: "Not available",
      youHaveNoActionToTake: "You have no action to take",
      actionsToBeTaken: "Actions to be taken",
      monitoringTheActionsNecessaryForTheLaunchOfYourCampaigns:
        "Monitoring the actions necessary for the launch of your campaigns",
      whatIsYourLevelOfSatisfactionWithThisService:
        "What is your level of satisfaction with this service?",
      commentOptional: "Comment (optional)",
      deleteFile: "Delete file",
      pleaseDropTheCreations: "Please drop the creations.",
      dragAndDropFilesHereOrClickToSelectFiles:
        "Drag and drop files here, or click to select files",
      confirmAndSendFiles: "Confirm and send files",
      failureToSend: "Failed to send",
      file: "File",
      sentSuccessfully: "sent successfully",
      startingToSend: "starting to send",
      advancedSearch: "Advanced Search",
      settings: "Settings",
      logout: "Logout",
      confirmDownload: "Confirm download",
      download: "Download",
      theActivityReport: "the activity report",
      theActivityReports: "the activity reports",
      cancel: "Cancel",
      confirm: "Confirm",
      downloadTheExcelOfActivityReports:
        "Download the Excel of activity reports",
      downloadThePDFOfTheActivityReport:
        "Download the PDF of the activity report",
      downloadPDFsOfActivityReports: "Download PDFs of activity reports",
      progressOfActivities: "Progress of activities",
      notStarted: "Not started",
      inProgress: "In progress",
      page: "Page",
      outOf: "out of",
      previous: "Previous",
      next: "Next",
      previewFile: "Preview file",
      impossible: "impossible",
      youAcceptedThis: "You accepted this",
      youRejectedThis: "You rejected this",
      weWillGetBackToYouWithANew: "We will get back to you with a new",
      at: "at",
      the: "the",
      pleaseFillTheReason: "Please fill the reason",
      goodToTake: "Bon à tirer",
      refuse: "Refuse",
      accept: "Accept",
      confirmTheRefusalOfThe: "Confirm the refusal of the",
      confirmTheAcceptanceOfThe: "Confirm the acceptance of the",
      pleaseExplainTheReasonForRefusingThe:
        "Please explain the reason for refusing the",
      weWillGetBackToYouShortly: "We will get back to you shortly.",
      pleaseValidateOrInvalidateThe: "Please validate or invalidate the",
      therePolitely: "here",
      incidentType: "Incident type",
      incidentReport: "Incident report",
      all: "All",
      toDo: "To do",
      searchAnActivity: "Search an activity",
      statistics: "Statistics",
      monthlyConnections: "Monthly connections",
      customerContacts: "Customer contacts",
      customerContact: "Customer contact",
      customer: "Customer",
      serviceProviderRsources: "Service provider resources",
      skills: "Skills",
      save: "Save",
      addASkill: "Add a skill",
      addAFunction: "Add a function",
      functions: "Functions",
      function: "Function",
      resources: "Resources",
      documents: "Documents",
      document: "Document",
      map: "Map",
      history: "History",
      contacts: "Contacts",
      providers: "Providers",
      addAProviderResource: "Add a provider resource",
      serviceProvider: "Service provider",
      validatedSkills: "Validated skills",
      freeField: "Free field",
      disabled: "Disabled",
      internal: "Internal",
      endDate: "End date",
      startDate: "Start date",
      noResult: "No result",
      quoteRequests: "Quote requests",
      quoteRequests_short: "Quote requests",
      newFilledReport: "New filled report",
      contractRefused: "Contract refused",
      contractFeedback: "Contract budget proposal",
      contractAccepted: "Contract accepted",
      newClient: "Client registration",
      newServiceProviderResource: "Resource registration",
      newDocument: "Client document",
      newIncidentReport: "Incident report",
      newLegalDocument: "Legal document provider",
      documentValidated: "Client document validation",
      documentRejected: "Client document rejection",
      date: "Date",
      type: "Type",
      label: "Label",
      notifications: "Notifications",
      serviceProviders: "Service providers",
      country: "Country",
      socialReason: "Company name",
      postalCode: "Postal code",
      address: "Address",
      latitude: "Latitude",
      longitude: "Longitude",
      geocode: "Geocode",
      autofillWithGoogleGeocode: "Autofill with Google geocode",
      downloadThePDF: "Download the PDF",
      fileNotDisplayable: "File not displayable",
      welcomeToTheNewCovworldBackoffice:
        "Welcome to the new Covworld Backoffice!",
      enterYourPassword: "Enter your password",
      passwordOrEmailIncorrect: "Password or email incorrect",
      path: "Path",
      schemes: "Schemes",
      addAScheme: "Add a scheme",
      legalForm: "Legal form",
      siretNumber: "SIRET number",
      workforce: "Workforce",
      saveTheClientAndAddContacts: "Save the client and add contacts",
      establishment: "Establishment",
      budget: "Budget",
      addAProviderContact: "Add a provider contact",
      missionContracts: "Mission contracts",
      activityType: "Activity type",
      resourceHistory: "Resource history",
      addAnIncidentType: "Add an incident type",
      addAnActivityType: "Add an activity type",
      materialResources: "Material resources",
      createdAt: "Created at",
      addABackOfficeUser: "Add a back office user",
      backOfficeUsers: "Back office users",
      unallocated: "Unallocated",
      prog: "Progress",
      misAct: "Mission Activities",
      quoteNumber: "Quote number",
      addACampaign: "Add a campaign",
      questionnaire: "Rating",
      addADocument: "Add a document",
      expirationDate: "Expiration date",
      category: "Category",
      ressourceLastName: "Resource last name",
      ressourceFirstName: "Resource first name",
      message: "Message",
      validate: "Validate",
      budgetFeedback: "Budget feedback",
      validateTheProposal: "Validate the proposal",
      contractProposals: "Contract proposals",
      purchaseOrder: "Purchase order",
      provider: "Provider",
      grade: "Grade",
      add: "Add",
      selected: "Selected",
      activitiesNumber: "Number of activities",
      contractNumber: "Contract number",
      requestedResources: "Requested resources",
      contracts: "Contracts",
      ofTheMission: "of the mission",
      addACustomerContact: "Add a customer contact",
      invitationEmailSent: "Invitation email sent",
      incidentsTypes: "Incident types",
      list: "List",
      missionPlace: "Mission place",
      review: "Review",
      addAMission: "Add a mission",
      providerContact: "Provider contact",
      processingFinished: "Processing finished",
      associatedDocuments: "Associated documents",
      searchInTable: "Search in the table",
      severalResources: "Several resources",
      materialResource: "Material resource",
      missingDocument: "Missing document",
      saveTheProviderAndAddContactsOrResources:
        "Save the provider and add contacts or resources",
      providerGrade: "Provider grade",
      addAProvider: "Add a provider",
      providerHistory: "Provider history",
      clickToDownload: "Click to download",
      reportToComplete: "Report to complete",
      downloadA: "Download a",
      clickHere: "Click here",
      optional: "Optional",
      required: "Required",
      edit: "Edit",
      preview: "Preview",
      activityTypeInTheMission: "Activity type in the mission",
      today: "Today",
      month: "Month",
      week: "Week",
      day: "Day",
      allDay: "All day",
      missionsCalendar: "Missions calendar",
      last30Days: "Last 30 days",
      last7Days: "Last 7 days",
      signedToStart: "Signed to start",
      finished_singular: "Finished",
      finished: "Finished",
      totalExternalResources: "Total external resources",
      customerRegistrations: "Customer registrations",
      resourceRegistrations: "Resource registrations",
      validator: "Validator",
      validatedOn: "Validated on",
      url: "URL",
      clients: "Clients",
      administration: "Administration",
      copiedLink: "Copied link",
      copyLink: "Copy link",
      docToShare: "Doc to share",
      downloadDocument: "Download document",
      downloadInvoice: "Download invoice",
      downloadBAT: "Download BAT",
      downloadQuote: "Download quote",
      validationDate: "Validation date",
      text: "Text",
      validateDocument: "Validate document",
      rejected: "Rejected",
      validated: "Validated",
      waiting: "Waiting",
      creation: "Creation",
      bat: "BAT",
      addImage: "Add image",
      selectImage: "Select image",
      remove: "Remove",
      notManaged: "Not managed",
      addActivity: "Add activity",
      positionNotAvailable: "Position not available",
      position: "Position",
      reports: "Reports",
      createReport: "Create report",
      reportCreatorResource: "Resource creator of the report",
      chooseResource: "Choose a resource",
      reportCreation: "Report creation",
      editReport: "Edit report",
      reportModification: "Report modification",
      addingResourcesGeneratesContractProposalAndNotification:
        "Adding resources generates a contract proposal and a notification for each selected resource.",
      leaveEmptyOrZeroToLetProviderProposeBudget:
        "Leave the field empty or at 0 to allow the provider to propose a budget",
      addContract: "Add a contract",
      vatNumber: "VAT number",
      campaignDocuments: "Campaign documents",
      awsKey: "AWS Key",
      searchBestProvidersFromSelectedActivities:
        "Search the best providers from selected activities",
      psFillLocationOnAtLeastOneActivityLessThan100km:
        "P.S.: Please fill the location on at least one activity to display nearby providers. (< 100km)",
      listOfRequiredSkills: "List of required skills",
      contract: "Contract",
      confirmTheContractProposalTheResourceWillBeAffectedAutomaticallyToAllActivitiesOfTheContract:
        "Confirm the contract proposal. The resource will be automatically assigned to all activities of the contract.",
      new_plurial_f: "New",
      accepted_plurial_f: "Accepted",
      closed_plurial_f: "Closed",
      contact: "Contact",
      createAccount: "Create an account",
      connect: "Connect",
      disconnect: "Disconnect",
      home: "Home",
      myProfile: "My profile",
      scan: "Scan",
      previousStep: "Previous step",
      nextStep: "Next step",
      signAndFinish: "Sign and finish",
      transmitInformationToCovworld: "Transmit information to Covworld",
      errorInReport: "Error in report",
      theFieldsOfTheReportAreNotCorrectlyFilled:
        "The fields of the report are not correctly filled",
      performTheDrawing: "Perform the drawing",
      changeThePictures: "Change the pictures",
      takeAPicture: "Take a picture",
      performTheSignature: "Perform the signature",
      covworldNeedsYourLocationToWork: "Covworld needs your location to work",
      start: "Start",
      doYouWantToStartThisNewActivity:
        "Do you want to start this new activity?",
      allowCovworldToAccessTheCamera: "Allow Covworld to access the camera",
      missingPermissionsToAccessTheCamera:
        "Missing permissions to access the camera",
      impossibleToTakeAPicture: "Impossible to take a picture",
      numberOfPhotosReached: "Number of photos reached",
      youHaveAlreadyProvidedTheRightNumberOfPhotos:
        "You have already provided the right number of photos",
      budgetToDefine: "Budget to define",
      yourProposal: "Your proposal",
      invalidEmail: "Invalid email",
      emailSent: "Email sent",
      youHaveReceivedInstructionsByEmail:
        "You have received instructions by email",
      firstNameRequired: "First name required",
      lastNameRequired: "Last name required",
      passwordRequired: "Password required",
      phoneRequired: "Phone required",
      invalidPhone: "Invalid phone",
      unableToRegister: "Unable to register",
      confirmation: "Confirmation",
      youCanNowLogIn: "You can now log in",
      passwordChanged: "Password changed",
      unableToUpdatePassword: "Unable to update password",
      seeTheReport: "See the report",
      startTheActivity: "Start the activity",
      reportAnIncident: "Report an incident",
      completeTheReport: "Complete the report",
      erase: "Erase",
      reportTheIncident: "Report the incident",
      previousIncidents: "Previous incidents",
      seeThePurchaseOrder: "See the purchase order",
      openInBrowser: "Open in browser",
      congratulations: "Congratulations",
      proposalAccepted: "Proposal accepted",
      someoneElseHasAlreadyAcceptedThisProposal:
        "Someone else has already accepted this proposal",
      statusUpdated: "Status updated",
      theBudget: "the budget",
      yourRefusal: "your refusal",
      youHaveProposedABudgetOf: "You have proposed a budget of",
      indicateABudget: "Indicate a budget",
      seeMyActivities: "See my activities",
      unableToSendEmail: "Unable to send email",
      sendMessageTo: "Send a message to",
      enterTheSubject: "Enter the subject",
      enterYourMessage: "Enter your message",
      noServiceAvailableToday: "No service available today",
      profile: "Profile",
      yourProfile: "Your profile",
      actionRegions: "Action regions",
      deleteMyAccount: "Delete my account",
      confirmDeletionIrreversible: "Confirm deletion (irreversible)",
      deletionFailed: "Deletion failed",
      accountDeleted: "Account deleted",
      documentType: "Document type",
      insuranceCertificate: "Insurance certificate",
      URSSAFCertificate: "URSSAF certificate",
      theDocumentExpires: "The document expires",
      chooseADate: "Choose a date",
      successfulSending: "Successful sending",
      sendingFailed: "Sending failed",
      addLegalDocuments: "Add legal documents",
      rib: "RIB",
      doYouWantToSaveChanges: "Do you want to save changes",
      leaveWithoutSaving: "Leave without saving",
      saveAndExit: "Save and exit",
      doNotExit: "Do not exit",
      doYouWantToSaveBeforeExiting: "Do you want to save before exiting?",
      errorOnReport: "Error on the report",
      expectedNumberOfPhotos: "Expected number of photos",
      noDescription: "No description",
      unableToRotatePicture: "Unable to rotate picture",
      unableToTakePicture: "Unable to take a picture",
      youDoNotHaveAccessToThisActivity:
        "You do not have access to this activity",
      reportSent: "Report sent",
      ok: "Ok",
      activityFinished: "Activity finished",
      yes: "Yes",
      no: "No",
      doubleClickInTheBoxBelowAndSelectTextScanToUseCharacterRecognition:
        "Double-click in the box below and select 'Text Scan' to use character recognition",
      pleaseEnterANewPassword: "Please enter a new password",
      enterYourNewPassword: "Enter your new password",
      passwordSuccessfullyChangedYouCanNowReturnToTheApplicationAndLogIn:
        "Password successfully changed. You can now return to the application and log in.",
      searchInTheCampaign: "Search in the campaign",
      allNotifications: "All notifications",
      customerFeedback: "Customer Feedback",
      validateBAT: "Validate BAT",
      waitingBAT: "Waiting for BAT",
      validateQuote: "Validate Quote",
      waitingQuote: "Waiting for Quote",
      toBeFinishedLater: "To be finished later",
      update: "Update",
      missionStarted: "Mission Started",
      publication: "Publication",
      publishOnPortal: "Publish on the portal",
      showReportsInPortal: "Show reports in the portal",
      addressRequired: "Address required",
      companyNameRequired: "Company name required",
      downloadTable: "Download table",
      downloadActivitiesQRCode: "Download activities QRCode",
      downloadActivityQRCode: "Download activity QRCode",
      delete: "Delete",
      inviteOnPortal: "Invite on portal",
      view: "View",
      confirmDeletitionOf: "Confirm deletion of",
      functionalDeletionOnlyIfActivityNotStartedOrAssigned:
        "Functional deletion only if activity not started or assigned",
      functionalDeletionOnlyIfMissionHasNoActivities:
        "Functional deletion only if mission has no activities",
      functionalDeletionOnlyIfCampaignHasNoMissions:
        "Functional deletion only if campaign has no missions",
      duplicateAndEdit: "Duplicate and edit",
      previewUnavailable: "Preview unavailable",
      userDisabled: "User disabled",
    },
  },
  fr: {
    translation: {
      loading: "Chargement",
      youDidNotRealizeAnyCampaignYet:
        "Vous n’avez pas encore réalisé de campagne",
      register: "S'inscrire",
      login: "Se connecter",
      passwordForgotten: "Mot de passe oublié",
      password: "Mot de passe",
      connectToYourClientSpace: "Connectez-vous à votre espace client",
      emailAddress: "Adresse email",
      incorrectPasswordOrEmail: "Mot de passe ou email incorrect",
      ifTheUserExistsEmailSent: "Si l'utilisateur existe, un mail a été envoyé",
      ifTheUserExistsEmailSentToTheProvidedAddressIfYouHaveNotReceivedAConfirmationEmailPleaseCheckYourSpamFolder:
        "Si l'utilisateur existe, un mail a été envoyé à l'adresse renseignée. Si vous n'avez pas reçu de mail de confirmation, veuillez vérifier votre courrier indésirable.",
      resetPassword: "Réinitialiser le mot de passe",
      resendEmail: "Renvoyer un email",
      searchForFieldInActivityReports:
        "Recherche d’un champ dans les rapports d’activités",
      search: "Rechercher",
      searchResults: "Résultats de la recherche",
      noResults: "Aucun résultat",
      campaign: "Campagne",
      mission: "Mission",
      activity: "Activité",
      companyName: "Nom de l'entreprise",
      changePassword: "Modifier le mot de passe",
      currentPassword: "Mot de passe actuel",
      newPassword: "Nouveau mot de passe",
      newPasswordConfirmation: "Confirmation du nouveau mot de passe",
      passwordModificationSuccess: "Mot de passe modifié avec succès",
      incorrectCurrentPassword: "Mot de passe actuel incorrect",
      heightCharactersMinimumIncludingOneNumberAndOneLetter:
        "8 caractères min. dont un chiffre et une lettre",
      modifyPassword: "Modifier le mot de passe",
      signUp: "Inscription",
      firstName: "Prénom",
      lastName: "Nom",
      company: "Société",
      email: "Email",
      phone: "Téléphone",
      confirmPassword: "Confirmation du mot de passe",
      thePasswordMustContainAtLeast8CharactersOneLetterAndOneNumber:
        "Le mot de passe doit contenir au moins 8 caractères, une lettre et un chiffre",
      thisFieldIsRequired: "Champ obligatoire",
      passwordNotMeetingRequirements:
        "Le mot de passe ne respecte pas les exigences",
      passwordsDoNotMatch: "Les mots de passe ne correspondent pas",
      errorOrEmailAlreadyUsed: "Erreur ou email déjà utilisé",
      registrationSuccessful: "Inscription réussie",
      error: "Erreur",
      requestNumber: "Numéro de demande",
      requestDate: "Date de demande",
      name: "Nom",
      progress: "Avancement",
      toBeProcessed: "À traiter",
      completed: "Terminée",
      completed_plural: "Terminées",
      newQuote: "Nouveau devis",
      newRequest: "Nouvelle demande",
      send: "Envoyer",
      comment: "Commentaire",
      requestedPrestations: "Prestations souhaitées",
      others: "Autres",
      other: "Autre",
      design: "Design",
      projectManagement: "Gestion de projet",
      installation: "Pose",
      printing: "Impression",
      deploymentDate: "Date de déploiement",
      deploymentPlace: "Lieu de déploiement",
      tarpaulin: "Bâche",
      adhesive: "Adhésif",
      paper: "Papier",
      typeOfAdhesiveDesired: "Type d'adhésif souhaité",
      typeOfInstallationDesired: "Type de pose souhaitée",
      signArea: "Surface du panneau",
      signNumber: "Nombre de panneaux",
      microperforated: "Microperforé",
      solidAdhesive: "Adhésif plein",
      easyApplicationAdhesiveYupoTako: "Adhésif pose facile (Yupo Tako)",
      windowShopArea: "Surface de la vitrine",
      windowShopNumber: "Nombre de vitrines",
      back: "Arrière",
      completeFlanks: "Flancs complets",
      carDoors: "Portières",
      fullCovering: "Covering complet",
      areasToCoverOnTheVehicle: "Zones à recouvrir sur le véhicule",
      numberOfVehicles: "Nombre de véhicules",
      bus: "Bus",
      heavyTruck: "Poids lourd",
      car: "Voiture",
      typeOfVehicle: "Type de véhicule",
      campaignName: "Nom de la campagne",
      sign: "Panneau",
      windowShop: "Vitrine",
      vehicles: "Véhicules",
      campaignType: "Type de campagne",
      newQuoteRequest: "Nouvelle demande de devis",
      quoteRequest: "Demande de devis",
      error404PageNotFound: "Erreur 404 - Page non trouvée",
      youWillBeRedirectedAutomaticallyToTheHomePage:
        "Vous allez être redirigé automatiquement vers la page d'accueil",
      returnToHomePageNow:
        "Retourner immédiatement à la page d'accueil maintenant",
      theLinkExpired: "Le lien a expiré",
      passwordAreNotIdentical: "Les mots de passe ne sont pas identiques",
      thePasswordHasBeen: "Le mot de passe a été",
      successfullyChanged: "modifié avec succès",
      goToTheLoginPage: "Aller à la page de connexion",
      description: "Description",
      status: "Statut",
      incidents: "Incidents",
      incident: "Incident",
      productive: "Productive",
      thisCampaignCannotBeFound: "Cette campagne est introuvable",
      yourCovworldContact: "Votre contact Covworld",
      covworldContact: "Contact Covworld",
      phoneNumber: "Numéro de téléphone",
      campaigns: "Campagnes",
      missions: "Missions",
      activities: "Activités",
      progresPercentage: "% d'avancement",
      followUpOfYourCampaigns: "Suivi de vos campagnes",
      location: "Localisation",
      validationOfTheQuote: "Validation du devis",
      dropTheCreations: "Déposer la/les créations",
      validateTheBAT: "Valider le BAT",
      informations: "Informations",
      invoice: "Facture",
      searchAMission: "Rechercher une mission",
      satisfactionQuestionnaire: "Questionnaire de satisfaction",
      bonATirer: "Bon à tirer",
      quote: "Devis",
      creations: "Créations",
      timestamp: "Horodatage",
      downloadTheCreation: "Télécharger la création",
      interventionReport: "Rapport d'intervention",
      timestampAndGPSPositioning: "Horodatage et positionnement GPS",
      geolocation: "Géolocalisation",
      notAvailable: "Non disponible",
      youHaveNoActionToTake: "Vous n'avez aucune action à effectuer",
      actionsToBeTaken: "Actions à effectuer",
      monitoringTheActionsNecessaryForTheLaunchOfYourCampaigns:
        "Suivi des actions nécessaires au lancement de vos campagnes",
      whatIsYourLevelOfSatisfactionWithThisService:
        "Quel est votre niveau de satisfaction sur cette prestation ?",
      commentOptional: "Commentaire (facultatif)",
      deleteFile: "Supprimer le fichier",
      pleaseDropTheCreations: "Veuillez déposer la ou les créations.",
      dragAndDropFilesHereOrClickToSelectFiles:
        "Faites glisser et déposez des fichiers ici, ou cliquez pour sélectionner des fichiers",
      confirmAndSendFiles: "Confirmer et envoyer les fichiers",
      failureToSend: "Échec de l'envoi de",
      file: "Fichier",
      sentSuccessfully: "envoyé avec succès",
      startingToSend: "démarrage de l'envoi",
      advancedSearch: "Recherche avancée",
      settings: "Paramètres",
      logout: "Se déconnecter",
      confirmDownload: "Confirmer le téléchargement",
      download: "Télécharger",
      theActivityReport: "le rapport d'activité",
      theActivityReports: "les rapports d'activité",
      cancel: "Annuler",
      confirm: "Confirmer",
      downloadTheExcelOfActivityReports:
        "Télécharger l'excel des rapports d'activité",
      downloadThePDFOfTheActivityReport:
        "Télécharger le PDF du rapport d'activité",
      downloadPDFsOfActivityReports:
        "Télécharger les PDF des rapports d'activité",
      progressOfActivities: "Avancement des activités",
      notStarted: "Non démarrée",
      inProgress: "En cours",
      page: "Page",
      outOf: "sur",
      previous: "Précédent",
      next: "Suivant",
      previewFile: "Prévisualisation du fichier",
      impossible: "impossible",
      youAcceptedThis: "Vous avez accepté ce",
      youRejectedThis: "Vous avez refusé ce",
      weWillGetBackToYouWithANew:
        "Nous allons revenir vers vous avec un nouveau",
      at: "à",
      the: "le",
      pleaseFillTheReason: "Veuillez remplir la raison",
      goodToTake: "Bon à tirer",
      refuse: "Refuser",
      accept: "Accepter",
      confirmTheRefusalOfThe: "Confirmer le refus du",
      confirmTheAcceptanceOfThe: "Confirmer l'acceptation du",
      pleaseExplainTheReasonForRefusingThe:
        "Veuillez nous expliquer la raison du refus du",
      weWillGetBackToYouShortly: "Nous reviendrons rapidement vers vous.",
      pleaseValidateOrInvalidateThe: "Veuillez valider ou invalider le",
      therePolitely: "ci-contre",
      incidentType: "Type d'incident",
      incidentReport: "Rapport d'incident",
      all: "Toutes",
      toDo: "À faire",
      searchAnActivity: "Rechercher une activité",
      statistics: "Statistiques",
      monthlyConnections: "Connexions mensuelle",
      customerContacts: "Contacts clients",
      customerContact: "Contact client",
      customer: "Client",
      serviceProviderRsources: "Ressources Prestataires de Service",
      skills: "Compétences",
      save: "Enregistrer",
      addASkill: "Ajouter une compétence",
      addAFunction: "Ajouter une fonction",
      functions: "Fonctions",
      function: "Fonction",
      resources: "Ressources",
      documents: "Documents",
      document: "Document",
      map: "Carte",
      history: "Historique",
      contacts: "Contacts",
      providers: "Prestataires",
      addAProviderResource: "Ajouter une ressource prestataire",
      serviceProvider: "Prestataire de service",
      validatedSkills: "Compétences validées",
      freeField: "Champ libre",
      disabled: "Désactivé",
      internal: "Interne",
      endDate: "Date fin",
      startDate: "Date début",
      noResult: "Aucun résultat",
      quoteRequests: "Demandes de devis",
      quoteRequests_short: "Demandes devis",
      newFilledReport: "Rapport rempli",
      contractRefused: "Contrat refusé",
      contractFeedback: "Contrat proposition budgétaire",
      contractAccepted: "Contrat accepté",
      newClient: "Inscription client",
      newServiceProviderResource: "Inscription ressource",
      newDocument: "Document client",
      newIncidentReport: "Rapport d'incident",
      newLegalDocument: "Document légal prestaire",
      documentValidated: "Validation document Client",
      documentRejected: "Rejet document Client",
      date: "Date",
      type: "Type",
      label: "Libellé",
      notifications: "Notifications",
      serviceProviders: "Prestataires de service",
      country: "Pays",
      socialReason: "Raison sociale",
      postalCode: "Code postal",
      address: "Adresse",
      latitude: "Latitude",
      longitude: "Longitude",
      geocode: "Géocode",
      autofillWithGoogleGeocode: "Remplissage automatique avec Google geocode",
      downloadThePDF: "Télécharger le PDF",
      fileNotDisplayable: "Fichier non affichable",
      welcomeToTheNewCovworldBackoffice:
        "Bienvenue sur le nouveau Backoffice de Covworld !",
      enterYourPassword: "Entrez votre mot de passe",
      passwordOrEmailIncorrect: "Mot de passe ou email incorrect",
      path: "Chemin",
      schemes: "Schémas",
      addAScheme: "Ajouter un schéma",
      legalForm: "Forme juridique",
      siretNumber: "Numéro de SIRET",
      workforce: "Effectif",
      saveTheClientAndAddContacts:
        "Enregistrer le client et rajouter des contacts",
      establishment: "Établissement",
      budget: "Budget",
      addAProviderContact: "Ajouter un contact prestataire",
      missionContracts: "Contrats Mission",
      activityType: "Type d'activité",
      resourceHistory: "Historique des ressources",
      addAnIncidentType: "Ajouter un type d'incident",
      addAnActivityType: "Ajouter un type d'activité",
      materialResources: "Ressources matérielles",
      createdAt: "Créé le",
      addABackOfficeUser: "Ajouter un utilisateur du back office",
      backOfficeUsers: "Utilisateurs du back office",
      unallocated: "Non alloué",
      prog: "Avct",
      misAct: "Mis. Act.",
      quoteNumber: "Numéro de devis",
      addACampaign: "Ajouter une campagne",
      questionnaire: "Note",
      addADocument: "Ajouter un document",
      expirationDate: "Date d'expiration",
      category: "Catégorie",
      ressourceLastName: "Nom de la ressource",
      ressourceFirstName: "Prénom de la ressource",
      message: "Message",
      validate: "Valider",
      budgetFeedback: "Retour budget",
      validateTheProposal: "Valider la proposition",
      contractProposals: "Propositions de contrat",
      purchaseOrder: "Bon de commande",
      provider: "Prestataire",
      grade: "Note",
      add: "Ajouter",
      selected: "Sélectionné",
      activitiesNumber: "Nombre d'activités",
      contractNumber: "Numéro de contrat",
      requestedResources: "Ressources sollicitées",
      contracts: "Contrats",
      ofTheMission: "de la mission",
      addACustomerContact: "Ajouter un contact client",
      invitationEmailSent: "Email d'invitation envoyé",
      incidentsTypes: "Types d'incidents",
      list: "Liste",
      missionPlace: "Lieu de la mission",
      review: "Évaluation",
      addAMission: "Ajouter une mission",
      providerContact: "Contact du prestataire",
      processingFinished: "Traitement terminé",
      associatedDocuments: "Documents associés",
      searchInTable: "Rechercher dans la table",
      severalResources: "Plusieurs ressources",
      materialResource: "Ressource matérielle",
      missingDocument: "Document manquant",
      saveTheProviderAndAddContactsOrResources:
        "Enregistrer le prestataire et rajouter des contacts ou ressources",
      providerGrade: "Note du prestataire",
      addAProvider: "Ajouter un prestataire",
      providerHistory: "Historique du prestataire",
      clickToDownload: "Cliquer pour télécharger",
      reportToComplete: "Rapport à compléter",
      downloadA: "Télécharger un/une",
      clickHere: "Cliquez ici",
      optional: "Optionnel",
      required: "Requis",
      edit: "Modifier",
      preview: "Prévisualisation",
      activityTypeInTheMission: "Type d'activité dans la mission",
      today: "Aujourd'hui",
      month: "Mois",
      week: "Semaine",
      day: "Jour",
      allDay: "Toute la journée",
      missionsCalendar: "Calendrier des missions",
      last30Days: "30 derniers jours",
      last7Days: "7 derniers jours",
      signedToStart: "Signées à lancer",
      finished_singular: "Terminée",
      finished: "Terminées",
      totalExternalResources: "Total ressources externes",
      customerRegistrations: "Inscriptions clients",
      resourceRegistrations: "Inscriptions ressources",
      validator: "Validateur",
      validatedOn: "Validé le",
      url: "URL",
      clients: "Clients",
      administration: "Administration",
      copiedLink: "Lien copié",
      copyLink: "Copier le lien",
      docToShare: "Doc à Partager",
      downloadDocument: "Télécharger un document",
      downloadInvoice: "Télécharger une Facture",
      downloadBAT: "Télécharger un BAT",
      downloadQuote: "Télécharger un Devis",
      validationDate: "Date de validation",
      text: "Texte",
      validateDocument: "Valider le document",
      rejected: "Rejeté",
      validated: "Validé",
      waiting: "En attente",
      creation: "Création",
      bat: "BAT",
      addImage: "Ajouter une image",
      selectImage: "Sélectionner une image",
      remove: "Retirer",
      notManaged: "Non géré",
      addActivity: "Ajouter une activité",
      positionNotAvailable: "Position non disponible",
      position: "Position",
      reports: "Rapports",
      createReport: "Créer un rapport",
      reportCreatorResource: "Resource créatrice du rapport",
      chooseResource: "Choisissez une ressource",
      reportCreation: "Création de rapport",
      editReport: "Modifier le rapport",
      reportModification: "Modification du rapport",
      addingResourcesGeneratesContractProposalAndNotification:
        "L'ajout de ressources génère une proposition de contrat et une notification pour chaque ressource sélectionnée.",
      leaveEmptyOrZeroToLetProviderProposeBudget:
        "Laisser le champ vide ou à 0 pour permettre au prestataire de proposer un budget",
      addContract: "Ajouter un contrat",
      vatNumber: "Numéro TVA",
      campaignDocuments: "Documents de la Campagne",
      awsKey: "Clé Aws",
      searchBestProvidersFromSelectedActivities:
        "Rechercher les meilleurs prestataires à partir des activités sélectionnées",
      psFillLocationOnAtLeastOneActivityLessThan100km:
        "P.S. : Veuillez remplir la localisation sur au moins une activité afin d'afficher les prestataires à proximité. (< 100km)",
      listOfRequiredSkills: "Liste des compétences requises",
      contract: "Contrat",
      confirmTheContractProposalTheResourceWillBeAffectedAutomaticallyToAllActivitiesOfTheContract:
        "Confirmer la proposition de contrat. La resource sera attribuée automatiquement à toutes les activités du contrat.",
      new_plurial_f: "Nouvelles",
      accepted_plurial_f: "Acceptées",
      closed_plurial_f: "Cloturées",
      contact: "Contact",
      createAccount: "Créer un compte",
      connect: "Connectez-vous",
      disconnect: "Déconnexion",
      home: "Accueil",
      myProfile: "Mon profil",
      scan: "Scan",
      previousStep: "Étape précédente",
      nextStep: "Étape suivante",
      signAndFinish: "Signer et terminer",
      transmitInformationToCovworld: "Transmettre les informations à Covworld",
      errorInReport: "Erreur dans le rapport",
      theFieldsOfTheReportAreNotCorrectlyFilled:
        "Les champs du rapport ne sont pas correctement remplis",
      performTheDrawing: "Effectuer le dessin",
      changeThePictures: "Changer les photos",
      takeAPicture: "Prendre une photo",
      performTheSignature: "Effectuer la signature",
      covworldNeedsYourLocationToWork:
        "Covworld à besoin de votre localisation pour fonctionner",
      start: "Débuter",
      doYouWantToStartThisNewActivity:
        "Voulez-vous débuter cette nouvelle activité ?",
      allowCovworldToAccessTheCamera:
        "Autoriser Covworld à accéder à l'appareil photo",
      missingPermissionsToAccessTheCamera:
        "Autorisations manquantes pour accéder à l'appareil photo",
      impossibleToTakeAPicture: "Impossible de prendre une photo",
      numberOfPhotosReached: "Nombre de photos atteint",
      youHaveAlreadyProvidedTheRightNumberOfPhotos:
        "Vous avez déja fourni le bon nombre de photos",
      budgetToDefine: "Budget à définir",
      yourProposal: "Votre proposition",
      invalidEmail: "Email invalide",
      emailSent: "Email envoyé",
      youHaveReceivedInstructionsByEmail:
        "Vous avez reçu des instructions par email",
      firstNameRequired: "Prénom requis",
      lastNameRequired: "Nom requis",
      passwordRequired: "Mot de passe requis",
      phoneRequired: "Téléphone requis",
      invalidPhone: "Téléphone invalide",
      unableToRegister: "Impossible de s'inscrire",
      confirmation: "Confirmation",
      youCanNowLogIn: "Vous pouvez désormais vous connecter",
      passwordChanged: "Mot de passe changé",
      unableToUpdatePassword: "Impossible de mettre à jour le mot de passe",
      seeTheReport: "Voir le rapport",
      startTheActivity: "Démarrer l'activité",
      reportAnIncident: "Signaler un incident",
      completeTheReport: "Compléter le rapport",
      erase: "Effacer",
      reportTheIncident: "Signaler l'incident",
      previousIncidents: "Incidents précédents",
      seeThePurchaseOrder: "Voir le bon de commande",
      openInBrowser: "Ouvrir dans un navigateur",
      congratulations: "Félicitations",
      proposalAccepted: "Proposition acceptée",
      someoneElseHasAlreadyAcceptedThisProposal:
        "Quelqu'un d'autre a déjà accepté cette proposition",
      statusUpdated: "Statut mis à jour",
      theBudget: "le budget",
      yourRefusal: "votre refus",
      youHaveProposedABudgetOf: "Vous avez proposé un budget de",
      indicateABudget: "Indiquer un budget",
      seeMyActivities: "Voir mes activités",
      unableToSendEmail: "Impossible d'envoyer l'email",
      sendMessageTo: "Envoyer un message à",
      enterTheSubject: "Entrer le sujet",
      enterYourMessage: "Entrer votre message",
      noServiceAvailableToday: "Aucune prestation disponible à ce jour",
      profile: "Profil",
      yourProfile: "Votre profil",
      actionRegions: "Régions d'action",
      deleteMyAccount: "Supprimer mon compte",
      confirmDeletionIrreversible: "Confirmer la suppression (irréversible)",
      deletionFailed: "La suppression a échoué",
      accountDeleted: "Compte supprimé",
      documentType: "Type de document",
      insuranceCertificate: "Attestation d'assurance",
      URSSAFCertificate: "Attestation d'URSSAF",
      theDocumentExpires: "Le document expire",
      chooseADate: "Choisir une date",
      successfulSending: "Envoi réussi",
      sendingFailed: "L'envoie a échoué",
      addLegalDocuments: "Ajouter des documents légaux",
      rib: "RIB",
      doYouWantToSaveChanges: "Voulez-vous sauvegarder les changements",
      leaveWithoutSaving: "Quitter sans sauvegarder",
      saveAndExit: "Sauvegarder et quitter",
      doNotExit: "Ne pas quitter",
      doYouWantToSaveBeforeExiting:
        "Voulez-vous sauvegarder avant de quitter ?",
      errorOnReport: "Erreur sur le rapport",
      expectedNumberOfPhotos: "Nombre de photos attendues",
      noDescription: "Aucune description",
      unableToRotatePicture: "Impossible de tourner la photo",
      unableToTakePicture: "Impossible de prendre une photo",
      youDoNotHaveAccessToThisActivity:
        "Vous n'avez pas accès à cette activité",
      reportSent: "Rapport envoyé",
      ok: "Ok",
      activityFinished: "Activité terminée",
      yes: "Oui",
      no: "Non",
      doubleClickInTheBoxBelowAndSelectTextScanToUseCharacterRecognition:
        "Double-cliquez dans la case ci-dessous et sélectionnez 'Scanner du texte' pour utiliser la reconnaissance de caractères",
      pleaseEnterANewPassword: "Veuillez renseigner un nouveau mot de passe",
      enterYourNewPassword: "Entrez votre nouveau mot de passe",
      passwordSuccessfullyChangedYouCanNowReturnToTheApplicationAndLogIn:
        "Mot de passe modifié avec succès. Vous pouvez maintenant retourner sur l'application et vous connecter.",
      searchInTheCampaign: "Rechercher dans la Campagne",
      allNotifications: "Toutes les notifs",
      customerFeedback: "Retour client",
      validateBAT: "Valider le BAT",
      waitingBAT: "En attente BAT",
      validateQuote: "Valider le devis",
      waitingQuote: "En attente devis",
      toBeFinishedLater: "À terminer plus tard",
      update: "Mise à jour",
      missionStarted: "Mission démarrée",
      publication: "Publication",
      publishOnPortal: "Publier sur le portail",
      showReportsInPortal: "Afficher les rapports dans le portail",
      addressRequired: "Adresse requise",
      companyNameRequired: "Nom de l'entreprise requis",
      downloadTable: "Télécharger la table",
      downloadActivitiesQRCode: "Télécharger les QRCode d'activités",
      downloadActivityQRCode: "Télécharger le QRCode d'activité",
      delete: "Supprimer",
      inviteOnPortal: "Inviter sur le portail",
      view: "Voir",
      confirmDeletitionOf: "Confirmer la suppression de",
      functionalDeletionOnlyIfActivityNotStartedOrAssigned:
        "Suppression fonctionnelle uniquement si l'activité n'est pas démarrée ou attribuée.",
      functionalDeletionOnlyIfMissionHasNoActivities:
        "Suppression fonctionnelle uniquement si la mission n'a pas d'activités.",
      functionalDeletionOnlyIfCampaignHasNoMissions:
        "Suppression fonctionnelle uniquement si la campagne n'a pas de missions.",
      duplicateAndEdit: "Dupliquer et modifier",
      previewUnavailable: "Aperçu indisponible",
      userDisabled: "Utilisateur désactivé",
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    // lng: "fr",
    interpolation: {
      escapeValue: false,
    },
  });

export { default } from "i18next"; // eslint-disable-line no-restricted-exports
