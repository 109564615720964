import type { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const DisplayPdf = ({ url }: { url: string }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const targetRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      if (targetRef.current) {
        setDimensions({
          width: targetRef.current.offsetWidth - 20,
          height: targetRef.current.offsetHeight,
        });
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onDocumentLoadSuccess = (pdf: PDFDocumentProxy) => {
    setNumPages(pdf.numPages);
  };

  return (
    <div style={{ marginBottom: 15 }}>
      <a style={{ display: "block", marginBottom: 8 }} href={url} download="">
        {t("downloadThePDF")}
      </a>
      <p>
        {t("page")} {pageNumber} {t("outOf")} {numPages}
      </p>
      <button
        onClick={(e) => {
          e.preventDefault();
          if (pageNumber > 1) setPageNumber(pageNumber - 1);
        }}
      >
        {t("previous")}
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          if (numPages !== null && pageNumber < numPages)
            setPageNumber(pageNumber + 1);
        }}
      >
        {t("next")}
      </button>
      <div ref={targetRef} style={{ width: "100%" }}>
        <Document
          file={url}
          onLoadSuccess={(pdf) =>
            onDocumentLoadSuccess(pdf as unknown as PDFDocumentProxy)
          }
        >
          <Page
            height={dimensions.height}
            width={dimensions.width}
            pageNumber={pageNumber}
          />
        </Document>
      </div>
    </div>
  );
};

const urlHasExtension = (url: string, extensions: string[]) =>
  extensions.some((extension) => url.endsWith(extension));

const isImage = (url: string) =>
  urlHasExtension(url, ["png", "jpg", "jpeg", "ico"]);
const isPDF = (url: string) => urlHasExtension(url, ["pdf"]);

const ShowDocument = ({ url, name }: { url?: string; name: string }) => {
  const { t } = useTranslation();
  if (url === undefined) {
    return (
      <div style={{ marginBottom: 15 }}>
        <div>{t("fileNotDisplayable")}</div>
      </div>
    );
  }

  if (isImage(url)) {
    return (
      <div style={{ marginBottom: 15 }}>
        <a
          style={{ display: "block", marginBottom: 8 }}
          href={`${url}?force=true`}
          download={name}
        >
          {t("download")} {name}
        </a>
        <img
          style={{ maxWidth: "100%", maxHeight: "600px" }}
          src={url}
          alt=""
        />
      </div>
    );
  }

  if (isPDF(url)) {
    return <DisplayPdf url={url} />;
  }

  return (
    <div style={{ marginBottom: 15 }}>
      <div>{t("fileNotDisplayable")}</div>
      <a href={`${url}?force=true`} download={name}>
        {t("download")}
      </a>
    </div>
  );
};

export default ShowDocument;
