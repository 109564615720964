/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  Center,
  LoadingOverlay,
  Modal,
  MultiSelect,
  NumberInput,
  Select,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import {
  ColumnDef,
  getCoreRowModel,
  getFacetedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import GlowingBox from "../Components/GlowingBox";
import Layout from "../Components/Layout";
import MainTable from "../Components/MainTable";
import MyDropzone from "../Components/MyDropzone";
import RoundedButton from "../Components/RoundedButton";
import ShowDocument from "../Components/ShowDocument";
import RoutesPath from "../Router/RoutesPath";
import Api from "../Services/API";
import { Document, Quote as QuoteType } from "../Types/client.type";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Quote = () => {
  const query = useQuery();
  const newValue = query.get("new") === "true";
  const [opened, { open, close }] = useDisclosure(newValue);
  const [loading, setLoading] = useState(false);
  const [startUploadDocumentsQuoteId, setStartUploadDocumentsQuoteId] =
    useState<number>(0);
  const [quotes, setQuotes] = useState<QuoteType[]>([]);
  const [updateQuotes, setUpdateQuotes] = useState<boolean>(true);
  const [sorting, setSorting] = React.useState<SortingState>([
    { id: "createdAt", desc: true },
  ]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState(false);
  const [documents, setDocuments] = useState<Document[]>([]);

  const columns: ColumnDef<QuoteType>[] = [
    {
      accessorKey: "id",
      header: () => {
        t("requestNumber");
      },
      cell: (info) => info.getValue(),
      enableColumnFilter: false,
    },
    {
      accessorKey: "createdAt",
      header: t("requestDate"),
      cell: (info) => moment(info.getValue() as Date).format("DD/MM/YYYY"),
      enableColumnFilter: false,
    },
    {
      accessorKey: "name",
      header: t("name"),
      cell: (info) => info.getValue(),
      enableColumnFilter: false,
    },
    {
      accessorKey: "processed",
      header: t("progress"),
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (info) => (
        <GlowingBox text={!info.getValue() ? "toBeProcessed" : "completed"} />
      ),
      enableColumnFilter: false,
    },
  ];

  useEffect(() => {
    const getData = async () => {
      if (updateQuotes) {
        const ret = await Api.getquotes();
        setQuotes(ret.data as QuoteType[]);
        console.log(ret.data);
        setUpdateQuotes(false);
      }
    };
    getData();
  }, [updateQuotes]);

  useEffect(() => {
    if (newValue) open();
  }, [newValue, open]);

  const form = useForm<{
    campaignType: string;
    name: string;
    deploymentPlace: string;
    deploymentDate: Date | undefined;
    desiredServices: string[];
    comment: string;

    vehiclesType: string;
    vehiclesNumber: number;
    vehiclesAreasToCover: string[];

    windowShopNumber: number;
    windowShopArea: string;
    windowShopAdhesiveType: string[];

    signNumber: number;
    signArea: string;
    signPoseType: string[];
  }>({
    initialValues: {
      campaignType: "",
      name: "",
      deploymentPlace: "",
      deploymentDate: undefined,
      desiredServices: [],
      comment: "",

      vehiclesType: "",
      vehiclesNumber: 0,
      vehiclesAreasToCover: [],

      windowShopNumber: 0,
      windowShopArea: "",
      windowShopAdhesiveType: [],

      signNumber: 0,
      signArea: "",
      signPoseType: [],
    },
    validate: {
      campaignType: (value) =>
        value.length === 0 ? t("thisFieldIsRequired") : null,
      name: (value) => (value.length === 0 ? t("thisFieldIsRequired") : null),
      desiredServices: (value) =>
        value.length === 0 ? t("thisFieldIsRequired") : null,
      vehiclesType: (value, values) =>
        value.length === 0 && values.campaignType === "vehicles"
          ? t("thisFieldIsRequired")
          : null,
      vehiclesNumber: (value, values) =>
        value === 0 && values.campaignType === "vehicles"
          ? t("thisFieldIsRequired")
          : null,
      vehiclesAreasToCover: (value, values) =>
        value.length === 0 && values.campaignType === "vehicles"
          ? t("thisFieldIsRequired")
          : null,
      windowShopNumber: (value, values) =>
        value === 0 && values.campaignType === "windowShop"
          ? t("thisFieldIsRequired")
          : null,
      windowShopAdhesiveType: (value, values) =>
        value.length === 0 && values.campaignType === "windowShop"
          ? t("thisFieldIsRequired")
          : null,
      signNumber: (value, values) =>
        value === 0 && values.campaignType === "sign"
          ? t("thisFieldIsRequired")
          : null,
      signPoseType: (value, values) =>
        value.length === 0 && values.campaignType === "sign"
          ? t("thisFieldIsRequired")
          : null,
    },
  });

  const cleanup = () => {
    form.reset();
    setUpdateQuotes(true);
    setStartUploadDocumentsQuoteId(0);
    setLoading(false);
    close();
    navigate(RoutesPath.quote);
    setViewMode(false);
  };

  const submitForm = async () => {
    if (form.validate().hasErrors) {
      console.log("Validation errors", form.errors);
    } else {
      try {
        setLoading(true);
        const ret: { data: { success: boolean; quoteId: QuoteType } } =
          await Api.quote(form.values);
        const quoteId = ret.data.quoteId as any as number;
        const success = ret.data.success as any as boolean;
        if (success) {
          // we need to upload the files now.
          setStartUploadDocumentsQuoteId(quoteId); // will trigger the upload of documents and at the end of the upload, it will call the cleanup function
          console.log("Success", quoteId);
        }
      } catch {
        toast.warn(t("error"), {
          icon: false,
        });
      }
    }
  };

  useEffect(() => {
    const handleViewQuote = (e: any) => {
      const { quoteId } = e.detail;
      open();
      setViewMode(true);
      const quote = quotes.find((q) => q.id === quoteId);
      if (quote) {
        setDocuments(
          (quote as QuoteType & { documents: Document[] }).documents
        );
        form.setValues({
          campaignType: quote.campaignType ?? "",
          name: quote.name ?? "",
          deploymentPlace: quote.deploymentPlace ?? "",
          deploymentDate: quote.deploymentDate
            ? new Date(quote.deploymentDate)
            : undefined,
          desiredServices: quote.desiredServices
            ? JSON.parse(quote.desiredServices)
            : [],
          comment: quote.comment ?? "",

          vehiclesType: quote.vehiclesType ?? "",
          vehiclesNumber: quote.vehiclesNumber ?? 0,
          vehiclesAreasToCover: quote.vehiclesAreasToCover
            ? JSON.parse(quote.vehiclesAreasToCover)
            : [],

          windowShopNumber: quote.windowShopNumber ?? 0,
          windowShopArea: quote.windowShopArea ?? "",
          windowShopAdhesiveType: quote.windowShopAdhesiveType
            ? JSON.parse(quote.windowShopAdhesiveType)
            : [],

          signNumber: quote.signNumber ?? 0,
          signArea: quote.signArea ?? "",
          signPoseType: quote.signPoseType
            ? JSON.parse(quote.signPoseType)
            : [],
        });
      }
    };

    window.addEventListener("viewQuote", handleViewQuote);
    return () => {
      window.removeEventListener("viewQuote", handleViewQuote);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, quotes]);

  const table = useReactTable({
    data: quotes,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  return (
    <Layout title={t("quoteRequest")}>
      <Modal
        opened={opened}
        onClose={() => {
          close();
          navigate(RoutesPath.quote);
          setViewMode(false);
          form.reset();
        }}
        title={t("newQuoteRequest")}
      >
        <LoadingOverlay visible={loading} />
        <div>
          <Select
            label={t("campaignType")}
            {...form.getInputProps("campaignType")}
            data={[
              { value: "vehicles", label: t("vehicles") },
              {
                value: "windowShop",
                label: t("windowShop"),
              },
              { value: "sign", label: t("sign") },
              { value: "others", label: t("others") },
            ]}
            dropdownPosition="bottom"
            withAsterisk
            miw={300}
            disabled={viewMode}
          />
          <TextInput
            label={t("campaignName")}
            {...form.getInputProps("name")}
            withAsterisk
            miw={300}
            disabled={viewMode}
          />
          {form.values.campaignType === "vehicles" && (
            <>
              <Select
                label={t("typeOfVehicle")}
                {...form.getInputProps("vehiclesType")}
                data={[
                  { value: "car", label: t("car") },
                  {
                    value: "truck",
                    label: t("heavyTruck"),
                  },
                  { value: "bus", label: t("bus") },
                  { value: "other", label: t("other") },
                ]}
                dropdownPosition="bottom"
                withAsterisk
                miw={300}
                disabled={viewMode}
              />
              <NumberInput
                label={t("numberOfVehicles")}
                {...form.getInputProps("vehiclesNumber")}
                withAsterisk
                miw={300}
                disabled={viewMode}
              />
              <MultiSelect
                label={t("areasToCoverOnTheVehicle")}
                {...form.getInputProps("vehiclesAreasToCover")}
                data={[
                  { value: "fullCovering", label: t("Covering complet") },
                  {
                    value: "carDoors",
                    label: t("carDoors"),
                  },
                  { value: "completeFlanks", label: t("completeFlanks") },
                  { value: "back", label: t("back") },
                  { value: "other", label: t("other") },
                ]}
                withAsterisk
                miw={300}
                disabled={viewMode}
              />
            </>
          )}
          {form.values.campaignType === "windowShop" && (
            <>
              <NumberInput
                label={t("windowShopNumber")}
                {...form.getInputProps("windowShopNumber")}
                withAsterisk
                miw={300}
                disabled={viewMode}
              />
              <TextInput
                label={t("windowShopArea")}
                {...form.getInputProps("windowShopArea")}
                miw={300}
                disabled={viewMode}
              />
              <MultiSelect
                label={t("typeOfAdhesiveDesired")}
                {...form.getInputProps("windowShopAdhesiveType")}
                data={[
                  {
                    value: "easy",
                    label: t("easyApplicationAdhesiveYupoTako"),
                  },
                  {
                    value: "solid",
                    label: t("solidAdhesive"),
                  },
                  { value: "microperforated", label: t("microperforated") },
                  { value: "other", label: t("other") },
                ]}
                withAsterisk
                miw={300}
                disabled={viewMode}
              />
            </>
          )}
          {form.values.campaignType === "sign" && (
            <>
              <NumberInput
                label={t("signNumber")}
                {...form.getInputProps("signNumber")}
                withAsterisk
                miw={300}
                disabled={viewMode}
              />
              <TextInput
                label={t("signArea")}
                {...form.getInputProps("signArea")}
                miw={300}
                disabled={viewMode}
              />
              <MultiSelect
                label={t("typeOfInstallationDesired")}
                {...form.getInputProps("signPoseType")}
                data={[
                  {
                    value: "paper",
                    label: t("paper"),
                  },
                  {
                    value: "adhesive",
                    label: t("adhesive"),
                  },
                  { value: "tarpaulin", label: t("tarpaulin") },
                  { value: "other", label: t("other") },
                ]}
                withAsterisk
                miw={300}
                disabled={viewMode}
              />
            </>
          )}
          <TextInput
            label={t("deploymentPlace")}
            {...form.getInputProps("deploymentPlace")}
            miw={300}
            disabled={viewMode}
          />
          <DateInput
            label={t("deploymentDate")}
            {...form.getInputProps("deploymentDate")}
            clearable
            disabled={viewMode}
          />
          <MultiSelect
            data={[
              { value: "printing", label: t("printing") },
              { value: "installation", label: t("installation") },
              { value: "projectManagement", label: t("projectManagement") },
              { value: "design", label: t("design") },
              { value: "others", label: t("others") },
            ]}
            label={t("requestedPrestations")}
            {...form.getInputProps("desiredServices")}
            withAsterisk
            disabled={viewMode}
          />
          {!viewMode && (
            <MyDropzone
              isQuote
              startUploadDocumentsQuoteId={startUploadDocumentsQuoteId}
              cleanup={cleanup}
            />
          )}
          <Textarea
            label={t("comment")}
            {...form.getInputProps("comment")}
            miw={300}
            minRows={4}
            disabled={viewMode}
          />
          {!viewMode && (
            <Center>
              <RoundedButton text={t("send")} action={submitForm} />
            </Center>
          )}
          {viewMode && documents.length > 0 && (
            <>
              <Text mt={2}>
                {t("associatedDocuments")} ({documents.length})
              </Text>
              {documents.map((doc) => (
                <ShowDocument
                  url={
                    doc?.awsKey.startsWith("https")
                      ? doc?.awsKey
                      : `https://${
                          window.location.href.includes("/client.covworld.fr")
                            ? "covworld-dev"
                            : "covworld-staging"
                        }.s3.eu-west-3.amazonaws.com/${doc?.awsKey}`
                  }
                  name={doc?.name}
                  key={doc.awsKey}
                />
              ))}
            </>
          )}
        </div>
      </Modal>
      <RoundedButton text={t("newQuote")} action={open} />
      <div style={{ height: 20 }} />
      <MainTable table={table} />
    </Layout>
  );
};

export default Quote;
