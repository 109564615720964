/* eslint-disable import/no-cycle */
import { Client } from "./client.type";
import { Mission } from "./mission.type";

export enum CampaignStatus {
  to_sign = "to_sign",
  signed_to_start = "signed_to_start",
  in_progress = "in_progress",
  to_close = "to_close",
  done = "done",
}

export type Campaign = {
  id: number;
  name: string;
  description: string;
  startDate: Date;
  endDate: Date;
  client: Client;
  campaign_clientId: number;
  location: string;
  quoteNumber: string;
  clientContact: string;
  comment: string;
  missions: Mission[];
  status: CampaignStatus;
  documents: CampaignDocument[];
  review: number;
  commentReview: string;
  reviewCompleted: boolean;
};

export type DocumentCategory = "quote" | "creation" | "bat" | "invoice";
export type DocumentStatus = "waiting" | "validated" | "rejected";

export type ActionType =
  | "waiting_for_quote"
  | "validate_quote"
  | "submit_creation"
  | "waiting_for_bat"
  | "validate_bat"
  | "customer_feedback";

export type Action = {
  campaignId: number;
  name: string;
  startDate: Date;
  endDate: Date;
  type: ActionType;
  numberMissions: number;
  numberActivities: number;
};

export type CampaignDocument = {
  id: number;
  name: string;
  campaign: Campaign;
  campaignId: number;
  awsKey: string;
  type: string;
  category: DocumentCategory;
  status: DocumentStatus;
  text: string;
  validator: string;
  createdAt: Date;
  validatedAt: Date;
};
