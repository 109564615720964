const squareButtonColor: Record<
  string,
  { background: string; border: string; color: string }
> = {
  white: { background: "white", border: "#D0D5DD", color: "#344054" },
  grey: { background: "#f9fafb", border: "#D0D5DD", color: "#344054" },
  green: { background: "#12B76A", border: "#039855", color: "white" },
  red: { background: "#F04438", border: "#B42318", color: "white" },
  purple: { background: "#7280DC", border: "#7280DC", color: "white" },
};

const SquareButton = ({
  text,
  action,
  color,
}: {
  text: string;
  action: () => Promise<void> | void;
  color: "red" | "green" | "white" | "grey" | "purple";
}) => {
  return (
    <button
      onClick={() => {
        action();
      }}
      type="submit"
      style={{
        width: "100%",

        background: squareButtonColor[color].background,
        border: `1px solid ${squareButtonColor[color].border}`,
        borderRadius: 8,

        fontStyle: "normal",
        fontWeight: 520,
        fontSize: 16,
        color: squareButtonColor[color].color,
        cursor: "pointer",
        marginTop: 20,
        padding: "12px 18px",
      }}
    >
      {text}
    </button>
  );
};
export default SquareButton;
