import { Activity } from "../Types/activity.type";

export const getActivitiesCountOfStatus = (
  activities: Activity[],
  status: string
): number => {
  return (
    activities?.filter((a) => a.status === status && a.type.productive)
      .length || 0
  );
};

export function getPercentagesOfActivities(
  total: number,
  toDo: number,
  done: number
) {
  // Ensure total is not zero to avoid division by zero
  if (total === 0) {
    return [0, 0, 0];
  }
  const percentToDo = Number.parseFloat(((toDo / total) * 100).toFixed(1));
  const percentDone = Number.parseFloat(((done / total) * 100).toFixed(1));
  // Calculate percentInProgress using subtraction to ensure the total is 100%
  const percentInProgress = Number.parseFloat(
    (100 - percentToDo - percentDone).toFixed(1)
  );

  return [percentToDo, percentInProgress, percentDone];
}
