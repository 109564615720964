/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { flexRender, Table } from "@tanstack/react-table";
import classNames from "classnames";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import RatingModal from "./RatingModal";
import SortArrow from "./SortArrow";

const MainTable = ({
  table,
  path,
  actions,
  search,
}: {
  table: Table<any>;
  path?: string;
  actions?: boolean;
  search?: boolean;
}) => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<boolean | number>(false);

  return (
    <div className="styled-table">
      <RatingModal openModal={openModal} setOpenModal={setOpenModal} />
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        className={classNames({
                          cursorPointer: header.column.getCanSort(),
                        })}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getCanSort() && (
                          <SortArrow direction={header.column.getIsSorted()} />
                        )}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              onClick={() => {
                if (window.location.pathname === "/quote") {
                  const viewQuote = new CustomEvent("viewQuote", {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    detail: { quoteId: row.original.id },
                  });
                  window.dispatchEvent(viewQuote);
                } else if (search) {
                  navigate(
                    `/campaign/${row.original.campaignId}/mission/${row.original.missionId}/activity/${row.original.activityId}`
                  );
                } else if (actions) {
                  if (row.original.type === "customer_feedback") {
                    setOpenModal(row.original.campaignId as number);
                  } else
                    navigate(
                      `${path}${row.original.campaignId}?action=${row.original.type}`
                    );
                } else if (path) navigate(`${path}${row.original.id}`);
              }}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default MainTable;
