import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Layout from "../Components/Layout";
import RoundedButton from "../Components/RoundedButton";
import RoutesPath from "../Router/RoutesPath";

const PageNotFound = () => {
  const navigate = useNavigate();
  const currentPath = window.location.pathname;
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      if (currentPath === window.location.pathname) navigate(RoutesPath.home);
    }, 5000);
  }, [navigate, currentPath]);

  // eslint-disable-next-line @typescript-eslint/require-await
  const handleSubmit = async () => {
    navigate(RoutesPath.home);
  };

  return (
    <Layout
      title={t("error404PageNotFound")}
      subtitle={t("youWillBeRedirectedAutomaticallyToTheHomePage")}
    >
      <div>
        <RoundedButton text={t("returnToHomePageNow")} action={handleSubmit} />
      </div>
    </Layout>
  );
};

export default PageNotFound;
