import { LoadingOverlay, Modal, Rating, Text, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { UserContext, UserInterface } from "../Context/UserContext";
import Api from "../Services/API";
import SquareButton from "./SquareButton";

const RatingModal = ({
  openModal,
  setOpenModal,
}: {
  openModal: number | boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<number | boolean>>;
}) => {
  const [loading, setLoading] = React.useState(false);
  const { refreshData } = useContext(UserContext) as UserInterface;
  const { t } = useTranslation();

  const form = useForm({
    initialValues: {
      review: 0,
      commentReview: "",
    },
    validate: {
      review: (value) => (value > 0 ? null : t("thisFieldIsRequired")),
    },
  });

  const sendFeedback = async () => {
    form.validate();
    if (form.isValid() && typeof openModal === "number") {
      // Your send feedback logic here
      setLoading(true);
      try {
        const ret = await Api.postFeedback(openModal, form.values as unknown);
        setLoading(false);
        if (ret.status === 200) {
          setOpenModal(false);
          refreshData();
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (openModal !== false) {
      form.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  return (
    <Modal
      opened={openModal !== false}
      onClose={() => setOpenModal(false)}
      title={t("satisfactionQuestionnaire")}
      centered
      styles={{ title: { fontSize: 22, fontWeight: 550 } }}
    >
      <LoadingOverlay visible={loading} />
      <Text>{t("whatIsYourLevelOfSatisfactionWithThisService")}</Text>
      {form.errors.review && (
        <Text color="red" size={14}>
          {form.errors.review}
        </Text>
      )}
      <Rating {...form.getInputProps("review")} mb={20} color="#7280DC" />

      <Text>{t("commentOptional")}</Text>
      <Textarea {...form.getInputProps("commentReview")} />
      <div style={{ margin: "0 auto", width: 140 }}>
        <SquareButton color="green" text={t("send")} action={sendFeedback} />
      </div>
    </Modal>
  );
};

export default RatingModal;
