import arrowDown from "../Assets/Icons/arrow_down.svg";
import arrowUp from "../Assets/Icons/arrow_up.svg";

const SortArrow = ({ direction }: { direction: "asc" | "desc" | boolean }) => {
  return (
    <img
      src={direction === "asc" ? arrowUp : arrowDown}
      alt="Icon"
      style={{
        maxWidth: 12,
        maxHeight: 12,
        marginLeft: 6,
        position: "relative",
        bottom: -2,
      }}
      className={direction !== false ? "filterPurpleArrow" : ""}
    />
  );
};
export default SortArrow;
