/* eslint-disable react/no-unstable-nested-components */
import {
  ColumnFiltersState,
  createColumnHelper,
  getCoreRowModel,
  getFacetedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { t as tt } from "i18next";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import DebouncedInput from "../Components/DebouncedInput";
import Downloader from "../Components/Downloader";
import GlowingBox from "../Components/GlowingBox";
import HorizontalPieChart from "../Components/HorizontalPieChart";
import Layout from "../Components/Layout";
import MainTable from "../Components/MainTable";
import MomentDateFormat from "../Components/MomentDateFormat";
import SelectBox from "../Components/SelectBox";
import { UserContext, UserInterface } from "../Context/UserContext";
import { CampaignStatus } from "../Types/campaign.type";
import { ExtendedCampaign } from "../Types/extendedCampaign";
import { storageToNumber, stringToStorage } from "../Utils/sortingString";
import { getActivitiesCountOfStatus } from "../Utils/tableCalculation";

export const campaignStatusRecord: Record<CampaignStatus, string> = {
  to_sign: tt("notStarted"),
  signed_to_start: tt("notStarted"),
  in_progress: tt("inProgress"),
  to_close: tt("finished_singular"),
  done: tt("finished_singular"),
};

const columnHelper = createColumnHelper<ExtendedCampaign>();

const Campaigns = () => {
  const { data: rawData, userContextLoading } = useContext(
    UserContext
  ) as UserInterface;
  const [rawDataExtended, setRawDataExtended] = useState<ExtendedCampaign[]>(
    []
  );
  const [data, setData] = useState<ExtendedCampaign[]>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const { t } = useTranslation();
  const [filterMode, setFilterMode] = useState<string>(t("all"));
  const [loading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    // Move this logic to the UserContext/backend if it is used multiple times
    const temp: ExtendedCampaign[] = rawData.map((campaign) => {
      const activities = campaign.missions.flatMap((m) => m.activities);
      const activitiesProductives = activities.filter((a) => a.type.productive);
      const temp2 =
        getActivitiesCountOfStatus(activitiesProductives, "done") +
        getActivitiesCountOfStatus(activitiesProductives, "in_progress") / 2;
      const percent = (
        (activitiesProductives.length === 0
          ? 0
          : temp2 / activitiesProductives.length || 0) * 100
      ).toFixed(0);
      return {
        ...campaign,
        advancement: stringToStorage(percent),
        numberMissions: stringToStorage(campaign.missions.length.toString()),
        numberActivities: stringToStorage(
          campaign.missions
            .flatMap((mission) => mission.activities)
            .filter((a) => a.type.productive)
            .length.toString()
        ),
        statusDisplay: campaignStatusRecord[campaign.status],
      };
    });
    setRawDataExtended(temp);
    setData(temp);
    setIsLoading(false);
  }, [rawData]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: t("campaign"),
        cell: (props) => (
          <>
            <div>{props.getValue()}</div>
            <div style={{ color: "#667085" }}>
              <MomentDateFormat
                date1={props.row.original.startDate}
                date2={props.row.original.endDate}
              />
            </div>
          </>
        ),
      }),
      columnHelper.accessor("statusDisplay", {
        header: t("status"),
        cell: (info) => <GlowingBox text={info.renderValue()} />,
      }),
      columnHelper.accessor("advancement", {
        header: t("progresPercentage"),
        cell: (props) => {
          return (
            <HorizontalPieChart advancement={+props.row.original.advancement} />
          );
        },
      }),
      columnHelper.accessor("numberMissions", {
        header: t("missions"),
        cell: (info) => {
          return (
            <div style={{ paddingLeft: 26 }}>
              {storageToNumber(info.renderValue())}
            </div>
          );
        },
      }),
      columnHelper.accessor("numberActivities", {
        header: t("activities"),
        cell: (info) => {
          return (
            <div style={{ paddingLeft: 26 }}>
              {storageToNumber(info.renderValue())}
            </div>
          );
        },
      }),
      columnHelper.accessor("id", {
        header: () => "",
        cell: (props) => (
          <Downloader
            campaignId={props.row.original.id}
            campaignName={props.row.original.name}
          />
        ),
        enableSorting: false,
      }),
    ],
    [t]
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  useEffect(() => {
    table.setSorting([{ id: "advancement", desc: false }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getState().columnFilters[0]?.id]);

  if (userContextLoading) {
    return (
      <Layout
        title={t("campaigns")}
        subtitle={t("followUpOfYourCampaigns")}
        big
      >
        <h1>{t("loading")}</h1>
      </Layout>
    );
  }

  if (!loading && rawData.length === 0) {
    return (
      <Layout
        title={t("campaigns")}
        subtitle={t("followUpOfYourCampaigns")}
        big
      >
        <h1>{t("youDidNotRealizeAnyCampaignYet")}</h1>
      </Layout>
    );
  }

  return (
    <Layout title={t("campaigns")} subtitle={t("followUpOfYourCampaigns")} big>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 16,
        }}
      >
        {filterMode}
        <SelectBox
          boxes={[
            t("all"),
            t("notStarted"),
            t("inProgress"),
            t("finished_singular"),
          ]}
          filterMode={filterMode}
          setFilterMode={setFilterMode}
          setData={setData}
          rawDataExtended={rawDataExtended}
        />
        <div className="inputTopRight">
          <DebouncedInput
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
            placeholder={t("search")}
          />
        </div>
      </div>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
      <MainTable table={table as any} path="/campaign/" />
    </Layout>
  );
};

export default Campaigns;
