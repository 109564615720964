import { t as tt } from "i18next";
import { useTranslation } from "react-i18next";

function getGlowingBoxColor(status: string): {
  backgroundColor: string;
  color: string;
} {
  switch (status) {
    case tt("notStarted"):
    case tt("toDo"):
    case tt("update"):
    case tt("customerFeedback"):
      return { backgroundColor: "#F4F3FF", color: "#5925DC" };
    case tt("inProgress"):
    case tt("reportSent"):
      return { backgroundColor: "#EFF8FF", color: "#175CD3" };
    case tt("finished_singular"):
    case tt("missionStarted"):
      return { backgroundColor: "#ECFDF3", color: "#027A48" };
    case tt("waitingQuote"):
    case tt("waitingBAT"):
    case tt("toBeFinishedLater"):
      return { backgroundColor: "#EFF4FF", color: "#0F162B" };
    case tt("validateQuote"):
    case tt("dropTheCreations"):
    case tt("validateBAT"):
      return { backgroundColor: "#FFFAEB", color: "#B54708" };
    case tt("incident"):
    case tt("toBeProcessed"):
      return { backgroundColor: "#FEF3F2", color: "#B42318" };
    default:
      return { backgroundColor: "#FFFFFF", color: "#000000" };
  }
}

const boxStyle: React.CSSProperties = {
  borderRadius: 20,
  display: "inline-block",
  padding: "1px 8px",
  fontWeight: 500,
  fontSize: 12,
  fontStyle: "normal",
  whiteSpace: "nowrap",
};

// example of inputs : completed / toBeProcessed
// then we want to extract the color from the text and at the end when we display we translate.
const GlowingBox = ({ text }: { text: string | null | number }) => {
  const { t } = useTranslation();
  if (!text) return null;
  if (typeof text === "number") {
    return (
      <span style={{ textAlign: "center" }}>
        <div
          style={{
            ...boxStyle,
            backgroundColor: "#f2f4f7",
            color: "#344054",
            minWidth: 6,
            marginLeft: 8,
          }}
        >
          {text}
        </div>
      </span>
    );
  }
  if (!Number.isNaN(+text)) {
    if (+text === 0) return <div style={{ textAlign: "center" }}>{text}</div>;
    return (
      <div style={{ textAlign: "center" }}>
        <div
          style={{
            ...boxStyle,
            backgroundColor: "#FEF3F2",
            color: "#B42318",
            minWidth: 6,
          }}
        >
          {text}
        </div>
      </div>
    );
  }
  return (
    <div
      style={{
        ...boxStyle,
        ...getGlowingBoxColor(text),
      }}
    >
      {t(text)}
    </div>
  );
};
export default GlowingBox;
