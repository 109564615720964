/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import "./lds-spinner.css";

import { useEffect, useState } from "react";

import Api from "../../Services/API";

export const Loader = () => (
  <div className="lds-spinner">
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
);

const ShowPicture = ({ pictureId }: { pictureId: string }) => {
  const [picture, setPicture] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getPictures = async () => {
      setIsLoading(true);
      try {
        const { data } = await Api.getPicture(pictureId);
        setPicture(data.path ?? "");
      } catch {
        console.error("getPictures");
      }
      setIsLoading(false);
    };
    getPictures();
  }, []);

  return (
    <div
      style={{
        width: 170,
        height: 170,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: 20,
        marginBottom: 20,
      }}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <img
          onClick={() => window.open(picture, "_blank")?.focus()}
          src={picture}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: 8,
            objectFit: "contain",
            cursor: "pointer",
          }}
          alt=""
        />
      )}
    </div>
  );
};

export default ShowPicture;
