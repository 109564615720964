import Cookies from "js-cookie";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import arrowLeft from "../Assets/Icons/arrow_left.svg";
import ImageLogo from "../Assets/logo_title.png";
import InputBox from "../Components/InputBox";
import RoundedButton from "../Components/RoundedButton";
import RoutesPath from "../Router/RoutesPath";
import Api from "../Services/API";
import Client from "../Services/Client";

export const PageTop = ({
  content,
  arrow = false,
}: {
  content: string;
  arrow?: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <>
      <img src={ImageLogo} style={{ width: 135 }} alt="logo" />
      <div style={{ position: "relative" }}>
        {arrow && (
          <img
            src={arrowLeft}
            alt="Icon"
            style={{
              position: "absolute",
              right: "330px",
              top: "43px",
              cursor: "pointer",
            }}
            onClick={() => navigate(RoutesPath.home)}
          />
        )}
        <h2 style={{ margin: "35px 0px" }}>{content}</h2>
      </div>
    </>
  );
};

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLogin = async () => {
    try {
      const ret = await Api.login(email, password);
      console.log("ret", ret);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
      Client.defaults.headers.common.Authorization = `Bearer ${ret.data.jwt}`; // ranger dans le local storage
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      Cookies.set("jwt", ret.data.jwt);
      window.location.href = "/";
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      if ((error as any).response?.data?.message === "User is Disabled") {
        toast.warn(t("userDisabled"), {
          icon: false,
        });
        return;
      }
      toast.warn(t("incorrectPasswordOrEmail"), {
        icon: false,
      });
    }
  };

  return (
    <div className="whiteWrapper">
      <div className="loginStyle">
        <PageTop content={t("connectToYourClientSpace")} />
        <InputBox text={t("emailAddress")} setInput={setEmail} />
        <InputBox text={t("password")} type="password" setInput={setPassword} />
        <p
          className="link14px"
          onClick={() => navigate(RoutesPath.resetPassword)}
        >
          {t("passwordForgotten")}
        </p>
        <RoundedButton text={t("login")} action={handleLogin} />
        <RoundedButton
          text={t("register")}
          action={() => navigate(RoutesPath.register)}
          color="#7580D3"
        />
      </div>
    </div>
  );
};

export default Login;
