import classNames from "classnames";

export type ActionTab = "interventionReport" | "incidents";
export type CampaignTab =
  | "missions"
  | "informations"
  | "quote"
  | "creations"
  | "bat"
  | "invoices";

type DynamicField = ActionTab | CampaignTab;

export const tabClassName = (
  thisTab: DynamicField,
  tabSelected: DynamicField
): string => {
  return classNames("tabButton", {
    tabOn: tabSelected === thisTab,
  });
};
