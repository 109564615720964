import Menu from "./Menu";

type LayoutProps = {
  children: JSX.Element | JSX.Element[];
  title?: string;
  subtitle?: string;
  big?: boolean;
};

const Layout = ({ children, title, subtitle, big }: LayoutProps) => {
  return (
    <div style={{ display: "flex" }}>
      <Menu />
      <div
        style={{
          padding: 24,
          width: "100%",
          height: "100%",
          overflowY: "auto",
          marginLeft: 300,
        }}
      >
        {title && (
          <h1
            style={{
              marginTop: 0,
              marginBottom: 4,
              color: "#101828",
              fontSize: big ? 30 : undefined,
            }}
          >
            {title}
          </h1>
        )}
        {subtitle && (
          <h2
            style={{
              margin: 0,
              color: "#667085",
              fontSize: 16,
              fontWeight: 400,
              marginBottom: 16,
            }}
          >
            {subtitle}
          </h2>
        )}
        {children}
      </div>
    </div>
  );
};

export default Layout;
