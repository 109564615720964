import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import IconValidated from "../Assets/Icons/big_validated.svg";
import InputBox from "../Components/InputBox";
import RoundedButton from "../Components/RoundedButton";
import RoutesPath from "../Router/RoutesPath";
import Api from "../Services/API";
import passwordCriteriaCheck from "../Utils/passwordCriteriaCheck";
import { PageTop } from "./Login";

const useQuery = () => new URLSearchParams(useLocation().search);

const NewPassword = () => {
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [success, setSuccess] = useState(false);
  const [unmatching, setUnmatching] = useState(false);
  const navigate = useNavigate();
  const query = useQuery();
  const token = query.get("token");
  const email = query.get("email");
  const { t } = useTranslation();

  if (!email || !token) navigate(RoutesPath.home);

  const handleSubmit = async () => {
    if (success) {
      navigate(RoutesPath.home);
      return;
    }
    if (password1 !== password2) setUnmatching(true);
    if (!passwordCriteriaCheck(password1, password2)) return;
    setUnmatching(false);
    try {
      await Api.newPassword(password1, token ?? "", email ?? "");
      setSuccess(true);
    } catch (error) {
      toast.warn(t("theLinkExpired"), {
        icon: false,
      });
      console.log(error);
    }
  };

  return (
    <div className="whiteWrapper">
      <div className="loginStyle">
        <PageTop content={success ? "" : t("newPassword")} />
        {!success ? (
          <>
            <InputBox
              text={t("newPassword")}
              type="password"
              setInput={setPassword1}
            />
            <p className="inputBoxInfoBellow marginLeft12">
              {t("heightCharactersMinimumIncludingOneNumberAndOneLetter")}
            </p>
            <InputBox
              text={t("newPasswordConfirmation")}
              type="password"
              placeholder={t("newPasswordConfirmation")}
              setInput={setPassword2}
            />
            {unmatching && <p>{t("passwordAreNotIdentical")}</p>}
          </>
        ) : (
          <div>
            <img
              src={IconValidated}
              alt=""
              style={{ margin: "auto", display: "block", marginTop: 30 }}
            />
            <h2 style={{ textAlign: "center" }}>{t("thePasswordHasBeen")}</h2>
            <h2 style={{ textAlign: "center", marginTop: -15 }}>
              {t("successfullyChanged")}
            </h2>
          </div>
        )}
        <RoundedButton
          text={success ? t("goToTheLoginPage") : t("resetPassword")}
          action={handleSubmit}
        />
      </div>
    </div>
  );
};

export default NewPassword;
