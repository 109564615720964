import warning from "../Assets/Icons/warning.svg";

const stringToImage: Record<string, string> = {
  warning,
};

const GlowingIcon = ({ icon, color }: { icon: "warning"; color: "red" }) => {
  return (
    <div
      style={{
        width: 24,
        height: 24,
        marginRight: 7,
        borderRadius: 12,
        backgroundColor: "#FEE4E2",
        textAlign: "center",
      }}
    >
      <img
        style={{ verticalAlign: "middle" }}
        src={stringToImage[icon]}
        alt="Icon"
      />
    </div>
  );
};
export default GlowingIcon;
