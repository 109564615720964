// This is just to avoid a bug in tanstack with global search in numbers
// When sorting by column, "4" was higher than "11" but lower than "9"
// So I transform all strings of numbers to strings of length 3
export const stringToStorage = (str: string): string => {
  return `00${str}`.slice(-3);
};

export const storageToNumber = (str: string | null): number => {
  if (!str) return 0;
  return +str;
};
